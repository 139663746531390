import { State, Action, Store } from '@ngxs/store';
import { mutateState } from '../app.utils';
import {NotificationsPage, Header} from './app.actions';
import { NotificationService } from '../services/notification.service';
import { LocalService } from '../services/local.service';
import {finalize, tap} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Cache } from '../classes/cache';
import * as _ from 'lodash';

@State({
    name: 'notificationsPage',
    defaults: {
        notifications: [],
        isReady: false,
        nextCursor: null,
    }
})
@Injectable()
export class NotificationsPageState {

    constructor(private notificationService: NotificationService,
                private localService: LocalService,
                private store: Store,
                @Inject('Cache') private cache: Cache) {}

    @Action(NotificationsPage.Init)
    init(ctx) {
      mutateState(ctx, draft => {
        draft.notifications = [];
        draft.isReady = false;
      });
      const notifications$ = this.notificationService.retrieveObjects({user: this.localService.getUser()}, ['actor']);
      return notifications$.pipe(
        tap((notification: any) => {
          mutateState(ctx, draft => {
            draft.notifications = notification.data;
            draft.nextCursor = notification.nextCursor;
            draft.isReady = true;
          });
        }),
        finalize(() => {
          mutateState(ctx, draft => {
            draft.isReady = true;
          });
        })
      );
    }

    @Action(NotificationsPage.RetrieveNotifications)
    retrieveNotifications(ctx, { cursor }) {
        return this.notificationService.retrieveObjects({user: this.localService.getUser(), cursor: cursor}, ['actor']).pipe(
          tap((notification: any) => {
            mutateState(ctx, draft => {
              draft.notifications.push(...notification.data);
              draft.nextCursor = notification.nextCursor;
              draft.isReady = true;
            });
          })
        );
    }

    @Action(NotificationsPage.UpdateIsSeen)
    updateIsSeen(ctx, { id, isSeen }) {
        return this.notificationService.updateObject({ id , isSeen: isSeen }, ['actor']).pipe(tap(n => {
            mutateState(ctx, draft => {
                let index = draft.notifications.findIndex(x => x.id === id);
                draft.notifications[index] = n;
                this.store.dispatch(new Header.UpdateIsSeen(isSeen));
            });
        }));
    }

    @Action(NotificationsPage.UpdateNotificationsIsSeen)
    updateAll(ctx) {
        this.store.dispatch(new Header.UpdateNotificationsIsSeen())
        var notifications = _.cloneDeep(ctx.getState().notifications).map((notification) => {
            notification.isSeen = true;
            return notification
        })
        mutateState(ctx, draft => {
            draft.notifications = notifications
        })
        return this.notificationService.updateObjects({isSeen: true},{isSeen: false}).pipe(tap(_ => {
            this.store.dispatch(new Header.GetUnseen())
        }))
    }

}
