import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {LocalePipe, DateFormatPipe} from 'ngx-moment';
import {concatMap, mergeMap, tap} from "rxjs/operators";
import {concat, of} from "rxjs";
import {translate,TranslocoService} from "@jsverse/transloco"


@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {
  key = 'notifications';

  constructor(public injector: Injector, private amLocal: LocalePipe, private amDateFormat: DateFormatPipe,private translocoService :TranslocoService) {
    super(injector);
  }





  transform(notification) {
    let d = this.amLocal.transform(notification.date, this.translocoService.getActiveLang()).toDate();
    d.setHours(0, 0, 0, 0);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);
    if (d.getTime() == today.getTime()) {
      notification['day'] = translate("Aujourd'hui");
    } else if (d.getTime() == yesterday.getTime()) {
      notification['day'] = translate('Hier');
    } else {
      notification['day'] = this.amDateFormat.transform(d, 'dddd D MMMM');
    }
    return notification;
  }

}
