import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoDirective } from '@jsverse/transloco';
@Component({
    selector: 'record-add',
    templateUrl: './record-add.component.html',
    styleUrls: ['./record-add.component.scss'],
    standalone: true,
    imports: [TranslocoDirective, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, AutocompleteComponent]
})
export class RecordAddComponent implements OnInit {

  @Input() entity;
  @Input() field;
  @Input() record;

  @Output() addSubmit = new EventEmitter();
  @Output() removeSubmit = new EventEmitter();

  @ViewChild('autocompleteRef') autocompleteRef;

  constructor() {}

  ngOnInit(): void {
  }

  autocompleteSelect(value: any) {
    const takenValues = this.autocompleteRef.takenValues;
    if (takenValues?.includes(value.id)) {
      this.removeSubmit.emit(value)
    } else {
      this.addSubmit.emit(value)
    }
  }
}
