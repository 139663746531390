import { 
  Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit, ChangeDetectorRef 
} from '@angular/core';
import { CommonModule } from '@angular/common'; // ✅ Required for *ngIf
import SignaturePad from 'signature_pad';

@Component({
  selector: 'signpad',
  templateUrl: './signpad.component.html',
  styleUrls: ['./signpad.component.scss'], 
  standalone: true,
  imports: [CommonModule]  
})
export class SignPadComponent implements AfterViewInit {
  @ViewChild('SignPadCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
  private signaturePad!: SignaturePad;
  isEmpty: boolean = true;
  @Output() signatureChange = new EventEmitter<string | null>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    const canvas = this.signatureCanvas.nativeElement;

    this.signaturePad = new SignaturePad(canvas, {
      minWidth: 1,
      maxWidth: 2,
      penColor: '#4f55da',
      backgroundColor: '#eff2f5'
    });

    canvas.width = canvas.parentElement?.clientWidth ;
    canvas.height = canvas.parentElement?.clientHeight > 250 ? canvas.parentElement.clientHeight : 250;

    if (this.signaturePad.isEmpty()) {
      this.signaturePad.clear();
      this.isEmpty = true; 
      this.signatureChange.emit('')
    }
    
    canvas.addEventListener('pointerup', () => {
      this.updateSignatureState();
    });

    this.cdr.detectChanges(); 
  }

  updateSignatureState() {
    this.isEmpty = this.signaturePad.isEmpty();
    if (!this.isEmpty) {
      this.signatureChange.emit(this.signaturePad.toDataURL('image/png'));
    } else {
      this.signatureChange.emit('');
    }
    this.cdr.detectChanges(); 
  }

  clearSignature() {
    this.signaturePad.clear();
    this.isEmpty = true; 
    this.signatureChange.emit('');
    this.cdr.detectChanges();
  }
}
