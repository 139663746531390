import {Component, Input, EventEmitter, Output, OnInit, ChangeDetectionStrategy} from '@angular/core';
import * as _ from 'lodash';
import {translate,TranslocoService} from "@jsverse/transloco";
import {Observable, forkJoin, of} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {ChoiceService} from "src/app/services/choice.service";
declare var $: any;

@Component({
    selector: 'pivottable',
    templateUrl: './pivot-table.component.html',
    styleUrls: ['./pivot-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})

export class PivotTableComponent implements OnInit {
  @Input() entity;
  @Input() data;
  @Input() config;
  @Input() initTrigger$!: Observable<void>;

  @Output() dashboardConfigChange = new EventEmitter();

  readonly #takeUntilDestroyed = takeUntilDestroyed();
  choices
  colorway=["#b8d935","#e8c444","#4fc9da","#4f55da","#31771c","#f06445","#9370db","#666666","#0094b6","#d84280","#2c3e50","#004d40","#795548","#e67e22"]
  colors = {
    "#b8d935": 'success',
    "#e8c444": 'warning',
    "#4fc9da": 'primary',
    "#4f55da": 'info',
    "#31771c": 'lime',
    "#f06445": 'danger',
    "#9370db": 'indego',
    "#666666": 'gray',
    "#0094b6": 'aqua',
    "#d84280": 'magenta',
    "#2c3e50": 'midnight',
    "#004d40": 'pine',
    "#795548": 'brown',
    "#e67e22": 'carrot'
  }
  constructor(private translocoService :TranslocoService,public choiceService: ChoiceService,) {
  }

  ngOnInit() {
    this.init();
    this.initTrigger$?.pipe(this.#takeUntilDestroyed).subscribe(() => this.init());
  }

  init(){
    const defaultConfig =  {
      onRefresh: (c) => this.onRefresh(c),
      rendererOptions: {plotly :{hovermode:false,colorway: this.colorway},localeStrings: {'totals': translate("Total"), 'selectNone': translate("Désélectionner tout")}},
      renderers: {
      },
      unusedAttrsVertical: false
    };

    defaultConfig.renderers[translate('Tableau')]= $.pivotUtilities.renderers['Table'],
    defaultConfig.renderers[translate('Tableau en barres')]= $.pivotUtilities.renderers['Table Barchart'],
    defaultConfig.renderers[translate('Carte de chaleur')]= $.pivotUtilities.renderers['Heatmap'],
    defaultConfig.renderers[translate('Carte de chaleur par ligne')]= $.pivotUtilities.renderers['Row Heatmap'],
    defaultConfig.renderers[translate('Carte de chaleur par colonne')]= $.pivotUtilities.renderers['Col Heatmap'],
    defaultConfig.renderers[translate('Graphique à barres')]= $.pivotUtilities.plotly_renderers['Bar Chart'],
    defaultConfig.renderers[translate('Graphique à barres horizontales')]= $.pivotUtilities.plotly_renderers['Horizontal Bar Chart'],
    defaultConfig.renderers[translate('Graphique à barres empilées')]= $.pivotUtilities.plotly_renderers['Stacked Bar Chart'],
    defaultConfig.renderers[translate('Graphique à barres empilées horizontales')]= $.pivotUtilities.plotly_renderers['Horizontal Stacked Bar Chart'],
    defaultConfig.renderers[translate('Graphique en courbes')]= $.pivotUtilities.plotly_renderers['Line Chart'],
    defaultConfig.renderers[translate('Graphique en surface')]= $.pivotUtilities.plotly_renderers['Area Chart'],
    defaultConfig.renderers[translate('Graphique en nuages de points')]= $.pivotUtilities.plotly_renderers['Scatter Chart'],
    defaultConfig.renderers[translate('Graphiques en secteurs multiple')]=$.pivotUtilities.plotly_renderers['Multiple Pie Chart']
    this.config = Object.assign({}, defaultConfig, this.config);
    this.drawPivot();
  }

  drawPivot() {
    $("#output").pivotUI(this.data, this.config, true, this.translocoService.getActiveLang());
  }

  onRefresh(newConfig) {
    this.useValueClassMappingColors(newConfig)
    this.formatPlotlyNumbers()
    const keys = ['cols', 'rows', 'vals', 'rowOrder', 'colOrder', 'exclusions', 'inclusions', 'aggregatorName', 'rendererName'];
    this.dashboardConfigChange.emit(_.pick(newConfig, keys));
    const classes = ['#droppableRows', '#droppableCols', '#droppableUnused'];
    $('.pvtAttr').addClass('btn new-gl-button btn-secondary mb-1')
    $('.pvtAggregator').addClass('form-control text-info')
    $('.pvtRenderer').addClass('form-control text-info font-weight-bold')
    $('.pvtAttrDropdown').addClass('form-control text-info')
    $('.pvtRows.ui-sortable').addClass('min-vh-100 min-w-125px w-100  d-inline-block');
    $('.pvtCols.ui-sortable').addClass('min-h-100px');
    $('.pvtUnused.ui-sortable').addClass("min-h-75px");
    classes.forEach(classe => {
        let pivot_class= classe.split('droppable')[1]
        $('.pvt' + pivot_class ).attr('id',classe.split('#')[1]);
        $(classe).droppable({
          over : function() {
            $('.pvt' + pivot_class + '.ui-sortable').addClass("bg-light");
          },
          out : function() {
            $('.pvt' + pivot_class + '.ui-sortable').removeClass("bg-light");
          },
          drop : function() {
            $('.pvt' + pivot_class + '.ui-sortable').removeClass("bg-light");
          }
       })
     })
    //make lenged circle
    $('.traces .layers .legendsymbols .legendpoints path').each(function(index,value) {
        $(this).attr("d","M -10,7 a 7,7 0 1,1 3,0")
    });
    //$('.pvtTable').addClass('table table-striped table-bordered table-sm table-hover')
    //$('.pvtTable th').addClass('bg-success text-white')
    //$('.pvtTable').removeClass('pvtTable')
    //$('.pvtAxisContainer').removeClass('pvtAxisContainer');
    //$('.pvtAttr').removeClass('pvtAttr')
  }

  setConfig(newConfig) {
    this.config = Object.assign({}, this.config, newConfig);
    this.drawPivot();
  }
  useValueClassMappingColors(newConfig){
    let newcolor = []
    let titles = []
    let names =[]
    if (newConfig != null && ((newConfig.rows.length === 1 && newConfig.cols.length === 0) || (newConfig.cols.length === 1 && newConfig.rows.length === 0))){
      let configField = newConfig.rows.length === 1 ? newConfig.rows : newConfig.cols
      this.entity.blocks.forEach(block => {
            block.fields.forEach(field=>{
                if (field.title.toUpperCase() == configField[0].toUpperCase()){
                  if (field.choiceset != null){
                    this.choiceService.retrieveObjects({choiceset: field.choiceset})
                    .pipe(map((response: any) => response.data || []))
                    .subscribe(
                      choices => {
                        this.choices=choices
                        // if legend is displayed
                        $('text.legendtext').each(function() {
                          titles.push($(this).text())
                          });
                        //if legend is not displayed
                        $('.xaxislayer-above .xtick').each(function() {
                          titles.push($(this).text())
                        });
                        // horizontal graphe
                        $('.yaxislayer-above .ytick').each(function() {
                          titles.push($(this).text())
                        });
                        // pie chart in with config.cols
                        $('g.titletext').each(function() {
                          titles.push($(this).text())
                        });
                        titles.forEach(title=>{
                          if (title=='- Non spécifié -'){
                            names.push('- Non spécifié -')
                          }
                          choices.forEach(choice=>{
                            if (title==choice.title){
                             names.push(choice.name)
                            }
                          })
                        });
                        names.forEach(name=>{
                          if (name=='- Non spécifié -'){
                            newcolor.push('#b5b0a1')
                          }
                          else {for (var key in field.valueClassMapping){
                           if (key==name){
                             let exist=false
                             for (var color in this.colors) {
                               if (field.valueClassMapping[key].includes(this.colors[color])){
                               exist=true
                               newcolor.push(color)
                               }
                             };
                             if(!exist && field.valueClassMapping[key].includes('light')){
                              newcolor.push('#F5F8FA')
                             }
                           }
                         }}
                        })
                        //change color in legend
                        $('.traces .layers .legendsymbols .legendpoints path').each(function(index,value) {
                          if (index < newcolor.length){
                            $(this).css("fill",newcolor[index])
                          }
                        });
                        // change color in legend if it's an area graphe
                        $('.traces .layers .legendfill path').each(function(index,value) {
                          if (index < newcolor.length){
                            $(this).css("fill",newcolor[index])
                          }
                        });
                        $('.traces .layers .legendlines path').each(function(index,value) {
                          if (index < newcolor.length){
                            $(this).css("fill",newcolor[index])
                            $(this).css("stroke",newcolor[index])
                          }
                        });
                        //change color in chart
                        $('.trace path').each(function(index,value) {
                          $(this).css("fill",newcolor[index])
                        });
                      });
                  }

                }
              ;
            })
          });
    }
  }
  formatPlotlyNumbers(){
    //yaxis
    $('.ytick text').each(function(index,value) {
      if ( $(this).text()!= 0 && $(this).text()  < 1 ){
        $(this).text(($(this).text() * 100).toFixed(0) + '%')
      }
    });
    let YlastElement = $('.ytick text').last();
    if (YlastElement.text() == 1){
      YlastElement.text('100%')
   }
   //xaxis
    $('.xtick text').each(function(index,value) {
      if ( $(this).text()!= 0 && $(this).text() < 1 ){
        $(this).text(($(this).text() * 100).toFixed(0) + '%')
      }
    });
    let xlastElement = $('.xtick text').last();
    if (xlastElement.text() == 1){
      xlastElement.text('100%')
   }
   //data values
    $('.trace text').each(function(index,value) {
      if (  $(this).text() < 1 ){
        $(this).text(($(this).text() * 100).toFixed(1) + '%')
      }
      if ($(this).text() == 1 && (xlastElement.text().endsWith('%') || YlastElement.text().endsWith('%'))){
        $(this).text('100%')
      }
    });
  }
}

