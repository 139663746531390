
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  ChangeDetectionStrategy
} from "@angular/core";
import { Store } from '@ngxs/store';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {concat, forkJoin, merge, Observable, of, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, tap} from "rxjs/operators";
import * as _ from "lodash";
import {RecordService} from "src/app/services/record.service";
import {ChoiceService} from "src/app/services/choice.service";
import {UserService} from "src/app/services/user.service";
import {LocalService} from "src/app/services/local.service";
import {EntityService} from "src/app/services/entity.service";
import {SpaceService} from "../../services/space.service";
import { translate, TranslocoDirective } from "@jsverse/transloco"
import { NgSelectComponent, NgSelectModule } from "@ng-select/ng-select";
import { FieldValueToClassPipe } from "../../pipes/field-value-to-class.pipe";
import { IconComponent } from "../icon/icon.component";
import { AutofocusDirective } from "../../directives/autofocus.directive";
import { NgbTypeahead, NgbHighlight } from "@ng-bootstrap/ng-bootstrap";
import { NgSwitch, NgSwitchCase, NgTemplateOutlet, NgIf, NgClass, NgStyle, AsyncPipe, JsonPipe } from "@angular/common";
declare var $: any;

@Component({
    selector: "autocomplete",
    templateUrl: "./autocomplete.component.html",
    styleUrls: ['autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgSwitch, NgSwitchCase, FormsModule, NgbTypeahead, AutofocusDirective, ReactiveFormsModule, NgTemplateOutlet, NgIf, NgSelectModule, IconComponent, NgbHighlight, NgClass, NgStyle, AsyncPipe, JsonPipe, FieldValueToClassPipe]
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() field;
  @Input() entityId;
  @Input() origin;
  @Input() isForm = false;
  @Input() isFilter = false;
  @Input() focused = false;
  @Input() takenValues = [];
  @Input() record = null;
  @Input() value = null;
  @Input() checkpoint = null;
  @Input() entity = null;
  @Input() showSelectedValue = false;
  @Input() placeholder = null;
  @Input() solid = true;
  @Output() autocompleteSelectItem = new EventEmitter();
  @Output() autocompleteUnselectItem = new EventEmitter();
  @ViewChild("searchRef") searchRef: ElementRef;
  @ViewChild("select") select: NgSelectComponent;
  @ViewChild("instance", {static: true}) instance;
  focus$ = new Subject<any>();
  click$ = new Subject<any>();
  typedInput$ = new Subject<any>();
  items$: Observable<any>;

  valueControl: any;
  elementToAdd: any = [];
  currentSelectedItems: any = [];
  currentSearchTerm: string = "";
  currentPage: number = 1;
  loading: boolean = false;
  canScroll: boolean = false;
  isChanged: boolean = false;
  listeningTypeAheadScroll: boolean = false;
  currentUser;
  hoveredTerm;

  readonly EMPTY_ITEM = { id: 'null' };

  constructor(
    public recordService: RecordService,
    public choiceService: ChoiceService,
    public userService: UserService,
    public spaceService: SpaceService,
    public localService: LocalService,
    public entityService: EntityService,
    private ref: ChangeDetectorRef,
    public store: Store,
  ) {
  }

  ngOnInit() {
    if (this.field) {
      this.field = _.cloneDeep(this.field);
      this.currentUser = this.store.snapshot().app.sider.user;

      if (this.value && this.isForm) {
        this.valueControl = new UntypedFormControl(
          this.field.type === 'Record' ? this.value.str :
            this.field.type === 'User' ? this.value.fullName :
              this.field.type === 'SingleSelect' || this.field.type === 'Space' ? this.value.title :
                ''
        );
        this.autocompleteSelectItem.emit(this.value);
      } else {
        this.valueControl = new UntypedFormControl('');
      }

      if (['MultiSelect', 'Users', 'Spaces'].includes(this.field.type)) {
        if (!this.record && !this.isForm) {
          const fieldTypeMapping = {
            'MultiSelect': 'SingleSelect',
            'Users': 'User',
            'Spaces': 'Space',
          }
          this.field.type = fieldTypeMapping[this.field.type];
        }

        const values = this.takenValues.length > 0 ? this.takenValues : this.value;
        this.currentSelectedItems = _.map(values, item => item.id);
      }

    }
    else if (this.checkpoint) {
      this.field = {
        type: this.checkpoint.type == 'Multi Select' ? 'MultiSelect' : 'SingleSelect',
        checkpoint: this.checkpoint
      }
      this.valueControl = new UntypedFormControl("");
    }
    this.initItems();
  }

  ngAfterViewInit(): void {
    if((this.select && !this.select?.isOpen ) && (this.isFilter ||  (this.focused && this.isForm))){
      this.select.open();
    }
    if (this.select && this.focused) {
      setTimeout(() => {
          this.select.focus();
      });
    }
  }

  initItems() {
    if (this.isFilter) {
      const values = this.takenValues.length > 0 ? this.takenValues : this.value;
      this.currentSelectedItems = _.map(values, item => item.id);
    }

    const debouncedText$ = this.typedInput$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    this.items$ = concat(
      of(this.takenValues),
      merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        tap(() => {this.loading = true; this.ref.detectChanges()}),
        mergeMap(term => {
          return this.fetchItems$(term).pipe(
            tap((items) => {
              if (!this.listeningTypeAheadScroll) {
                this.loading = false;
                setTimeout(() => {
                  let itemsWindow = document.getElementsByTagName("ngb-typeahead-window")[0] || document.getElementsByClassName("ng-dropdown-panel-items")[0];
                  if (itemsWindow) {
                    this.ref.detectChanges();
                    itemsWindow.addEventListener("scroll", (event) => {
                      if (this.canScroll && (itemsWindow.scrollTop + itemsWindow.clientHeight + 2 >= itemsWindow.scrollHeight)) {
                        this.listeningTypeAheadScroll = true;
                        this.currentPage++;
                        this.canScroll = false;
                        inputFocus$.next(this.currentSearchTerm)
                      }
                    }, false);
                  } else {
                    this.loading = false;
                  }
                })
              } else {
                this.loading = false;
              }
            })
          )
        })
      )
    );
  }

  getItems$ = (text$?: any) => {
    if (text$) {
      text$.subscribe((t) => {
        this.typedInput$.next(t);
      })
    }
    return this.items$;
  }

  fetchItems$(term: any) {
    if (term !== this.currentSearchTerm) this.currentPage = 1;
    this.currentSearchTerm = term;

    const hasAutocompleteOrderingOrFilter = this.field.hasAutocompleteFilter || this.field.hasAutocompleteOrderby;
    let params: { [key: string]: any } = {
      cursor: -1,
      page_size: 18 * this.currentPage,
      search: term,
      ...(!this.isFilter && hasAutocompleteOrderingOrFilter ? {
        field: this.field.id,
        ...(this.record ? { record: this.record.id } : {}),
        ...(this.isForm ? { form: this.field.form } : {}),
        entity: this.isForm ? this.entityId : this.field.entity
      } : {})
    };

    if (['User', 'CreatedBy', 'Users', 'E-Signature'].includes(this.field.type)) {
      term = term === null && this.focused ? '' : term;
      if (this.record && !this.field.allowDeniedUsersSelection) params = { ...params, record: this.record.id };

      return this.userService.retrieveObjects(params).pipe(
        map((res: any) => this.filteredItems(res.data, true, false))
      );
    }

    if (this.field.type === 'Space') {
      if (this.isFilter) {
        const spaces = this.store.selectSnapshot(state => state.app.sider.flatSpaces)
        return of(this.filteredItems(_.filter(spaces, (s) => this.entity.spaces.includes(s.id)), false, false));
      }
      return this.spaceService.retrieveObjects(params).pipe(
        map((res: any) => this.filteredItems(res.data, true, true))
      );
    }

    if (this.field.type === 'SingleSelect') {
      term = this.field.hasTooManyChoices && term === null && this.focused ? '' : term || '';
      params = this.checkpoint
        ? { ...params, checkpoint: this.checkpoint.id }
        : { ...params, choiceset: this.field.choiceset };

      return this.choiceService.retrieveObjects(params).pipe(
        map((res: any) => {
          return this.filteredItems(res.data, true, true);
        })
      );
    }

    if (this.field.type === 'MultiSelect') {
      params = this.checkpoint
        ? { ...params, checkpoint: this.checkpoint.id }
        : { ...params, choiceset: this.field.choiceset };

      if (this.checkpoint) this.currentSelectedItems = this.checkpoint.choices.filter(
        item => this.checkpoint.evaluation?.includes(item.title)).map(item => item.id);
      return this.choiceService.retrieveObjects(params).pipe(
        map((res: any) =>  this.filteredItems(res.data))
      );
    }

    if (this.field.type === 'Spaces') {
      params = { ...params, space: this.record.space };
      return this.spaceService.retrieveObjects(params).pipe(
        map((res: any) => this.filteredItems(res.data))
      );
    }

    if (['OneToOne', 'InverseOneToOne', 'OneToMany', 'InverseOneToMany', 'ManyToMany', 'InverseManyToMany'].includes(this.field.type)) {
      params = { ...params, entity: this.field.mirrorEntities };
      if (this.origin){
        const mirrorField = this.field.mirrorFields[0];
        const originParams = {
          ...params,
          entity: this.field.mirrorEntities,
          [mirrorField.name]: this.origin.id,
           origin_field: mirrorField.name,
        };
        const originRecords$ = this.recordService.retrieveObjects(originParams);
        const records$ = this.recordService.retrieveObjects(params);
        return forkJoin(originRecords$, records$).pipe(
          mergeMap(([originRecords, records]: any) => {
            this.takenValues = _.map(originRecords.data, (r) => r.id);
            return of(records);
          }),
          map((res: any) => this.filteredItems(res.data, true))
        );
      }
      return this.recordService.retrieveObjects(params).pipe(
        map((res: any) => this.filteredItems(res.data, true))
      );
    }

    if (this.field.type === 'Record') {
      params = { ...params, entity: this.field.entities };
      return this.recordService.retrieveObjects(params).pipe(
        map((res: any) => this.filteredItems(res.data))
      );
    }

    if (this.field.type === 'Checklist') {
      return this.entityService.retrieveObjects({
        space: this.localService.getSpace(),
        extension: 'Checklist'
      }, []).pipe(
        mergeMap((entityResponse: any) => {
          // Extract the entities from the API response
          const { data: entities = [] } = entityResponse;
          if (entities.length > 0) {
            // Use the first entity's id for the record query.
            return this.recordService.retrieveObjects({
              ...params,
              entity: entities[0].id
            }).pipe(
              mergeMap((recordResponse: any) => {
                // Extract the items from the record response.
                const { data: items = [] } = recordResponse;
                return of(items);
              }),
              tap((items: any) => this.filteredItems(items))
            );
          } else {
            // If no entities were returned, emit an empty array.
            return of([]);
          }
        })
      );
    }

  }

  onBlur() {
    if (this.isChanged) {
      this.autocompleteSelectItem.emit(this.elementToAdd);
    }
  }

  onSubmit(added: any) {
    const itemFromValue = {
      'Spaces': (item) => this.getItemFromValue(item, 'title'),
      'Users': (item) => this.getItemFromValue(item, 'fullName'),
      'User': (item) => this.getItemFromValue(item, 'fullName'),
      'CreatedBy': (item) => this.getItemFromValue(item, 'fullName'),
      'E-Signature': (item) => this.getItemFromValue(item, 'fullName'),
      'MultiSelect': (item) => this.getItemFromValue(item, 'title'),
      'SingleSelect': (item) => this.getItemFromValue(item, 'title'),
      'Record': (item) => item.str,
    };
    const getItemFromValue = (type: string, item: any) => itemFromValue[type] ? itemFromValue[type](item) : '';

    if (['Spaces', 'Users', 'MutliSelect'].includes(this.field.type) || (['CreatedBy', 'E-Signature', 'User', 'SingleSelect'].includes(this.field.type) && this.isFilter)) {
      added = _.map(added, (item) => getItemFromValue(this.field.type, item));
      this.elementToAdd = added;
      this.isChanged = true;
    } else {
      // this.value = added.id;
      if (_.isEqual(added, this.EMPTY_ITEM)) added = null;
      this.valueControl = new UntypedFormControl(this.showSelectedValue && added ? getItemFromValue(this.field.type, added) : "");
      this.searchRef?.nativeElement.blur();
      this.autocompleteSelectItem.emit(added);
    }
    if(this.isFilter) {
      this.autocompleteSelectItem.emit(added);
    }
  }

  focus(event: any) {
    if (this.localService.isMobile() && !this.canScroll && !this.checkpoint && this.currentPage === 1 ) {
      event.target.blur();
    }
    if (this.showSelectedValue) this.valueControl = new UntypedFormControl(this.value?this.currentSearchTerm:'');
    setTimeout(() => {
      this.focus$.next(event.target.value);
    });
  }

  focusout(event) {
    this.listeningTypeAheadScroll = false;
    this.loading = false;
    if(event.relatedTarget){
      this.valueControl = new UntypedFormControl('')
    }
  }

  getItemFromValue(item, key) {
    // Get object from record value if items visible
    if (_.isEqual(item, this.EMPTY_ITEM)) {
      return {
        ...this.EMPTY_ITEM,
        [key]: '--'
      };
    }
    if (item && !item[key]) {
      return _.find(this.value, u => u.id === item.id);
    }
    return item;
  }

  trackByFn(item: any) {
    return item.id;
  }

  private filteredItems(items: any, checkMandatory = false, nullable = false) {
    let filtered = [];
    const isNullableType = _.includes([
      'User', 'CreatedBy', 'Users', 'E-Signature',
      'OneToOne', 'InverseOneToOne', 'OneToMany', 'InverseOneToMany', 'Users', 'ManyToMany', 'InverseManyToMany',
      'SingleSelect', 'MultiSelect', 'Checklist'
    ], this.field.type);

    const isEmptyItemTaken = _.some(this.takenValues, { ...this.EMPTY_ITEM });

    _.forEach(items, (item) => {
      let find = _.some(this.takenValues, (v) => v.id !== 'null' && item.id !== 'null' && item.id === v.id) ||
        (nullable && item === 'null' && isEmptyItemTaken);

      if (_.includes(['User', 'Users', 'E-Signature'], this.field.type) && item.id === this.currentUser.id) {
        find = true;
      }

      if (!find) {
        filtered.push(item);
      }
    });

    if (this.currentSearchTerm === '' && _.includes(['User', 'Users', 'E-Signature'], this.field.type) && !_.includes(filtered, this.currentUser)) {
      filtered.unshift(this.currentUser);
    }

    if (this.field.type === 'SingleSelect' && this.checkpoint) {
      filtered.unshift({ ...this.EMPTY_ITEM, name: null });
    }

    if (!this.field.isMandatory) {
      if (this.isFilter && isNullableType) {
        filtered.unshift(this.EMPTY_ITEM);
      } else if (!this.isFilter && checkMandatory && this.field.isMandatory !== undefined && !isEmptyItemTaken && !this.showSelectedValue) {
        if (this.value && (_.includes(["User", "SingleSelect"], this.field.type) || _.includes(["OneToOne", "InverseOneToOne", "OneToMany"], this.field.type))) {
          filtered.unshift(this.EMPTY_ITEM);
        }
      }
    }

    if (this.field.canAdd && _.includes(['InverseOneToOne', 'InverseOneToMany', 'InverseManyToMany', 'Record', 'ManyToMany'], this.field.type)) {
      filtered = this.sortItems(filtered);
    }

    this.canScroll = (18 * this.currentPage <= filtered.length);

    return filtered;
  }

  private sortItems(filtered) {
    const takenValuesSet = new Set(this.takenValues);
    return _.sortBy(filtered, [
      (item) => !takenValuesSet.has(item.id),  // Prioritize taken values (false comes before true)
      (item) => item                          // Sort alphabetically
    ]);
  }

  isItemSelected(record: any): boolean {
    return this.takenValues.includes(record.id);
  }

  onClearItemClick(item, clearFn): void {
    clearFn(item);
    this.autocompleteUnselectItem.emit(item);
  }

  formatInputValue(item) {
    if (typeof item === 'string') {
      return item;
    }
    return item ? (item.title || item.str || item.fullName) : '';
  }
}
