import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ChangeDetectionStrategy,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { DOCUMENT, NgIf, NgClass, NgTemplateOutlet, NgFor, AsyncPipe } from '@angular/common';
import {Select, Store} from '@ngxs/store';
import {AppState} from 'src/app/state/app.state';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LocalService} from "../../services/local.service";
import {UserService} from "../../services/user.service";
import {GtmService} from "../../services/gtm.service";
import {Cache} from "../../classes/cache";
import {WebSocketService} from "../../services/websocket.service";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {Header, NotificationsPage, RecordPage, Sider} from "../../state/app.actions";
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {combineLatest, Subject, Observable} from 'rxjs';
import { GetPageTitlePipe } from '../../pipes/get-page-title.pipe';
import { GetEntityPipe } from '../../pipes/get-entity.pipe';
import { FlattenEntitiesPipe } from '../../pipes/flatten-entities.pipe';
import { SpecialTitleCasePipe } from '../../pipes/special-title.pipe';
import { NgArrayPipesModule } from 'ngx-pipes';
import { CheckDirective } from '../../directives/check.directive';
import { IconComponent } from '../icon/icon.component';
import { TrackDirective } from '../../directives/track.directive';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { TranslocoDirective } from '@jsverse/transloco';


declare var $: any;
enum Level {
  None = 0,
  Read = 1,
  Write = 2,
  Administrate = 3,
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, BreadcrumbComponent, FormsModule, ReactiveFormsModule, NgClass, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgTemplateOutlet, RouterLink, RouterLinkActive, TrackDirective, NgFor, IconComponent, NgbDropdownItem, CheckDirective, AsyncPipe, NgArrayPipesModule, SpecialTitleCasePipe, FlattenEntitiesPipe, GetEntityPipe, GetPageTitlePipe]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(AppState.recordsPage) recordsPage$;
  @Select(AppState.sider) sider$;
  get recordPage$(): Observable<any>  {
    return this.store.select(AppState.recordPage(this.recordId));
  }
  @HostListener('window:resize')
  public onResize() {
    this.changeDetector.detectChanges();
  }

  search = new UntypedFormControl('');
  space = this.localService.getSpace();
  previousSearchValue: string = null;
  destroyed$: Subject<any> = new Subject();
  routeIsRecord = false;
  routeIsRecords = false;
  routeIsHome = false;
  isPageSetActive = false;
  isSiderVisible = this.localService.getSiderVisibility();
  theme = this.localService.getTheme();
  recordId;

  constructor(
      public store: Store,
      public userService: UserService,
      public localService: LocalService,
      private gtmService:GtmService,
      private changeDetector:ChangeDetectorRef,
      @Inject('Cache') private cache: Cache,
      public router: Router,
      public route: ActivatedRoute,
      private toastr: ToastrService,
      private webSocketService: WebSocketService,
      @Inject(DOCUMENT) private document: Document
  ) {
      this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe(params => {
       this.recordId = params.get('id');
      });
   }

  ngOnInit() {
    this.getCurrentRoute();
    this.localService.siderVisibility$.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
      this.isSiderVisible = val;
      this.changeDetector.detectChanges();
    })
    this.localService.theme$.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
        this.theme = val;
      })
    this.webSocketService.messages.pipe(filter(data => (data !==  null && data.message.type === 'notification')),
        takeUntil(this.destroyed$)).subscribe((data) => {
      const message = data.message;
      const notification = message.data;
      const description = notification.description;
      const count = notification?.count ? notification.count : 1;
      this.store.dispatch(new Header.AddNotification(count));
      if (this.router.isActive('/notifications', true))
          this.store.dispatch(new NotificationsPage.Init())
      this.toastr.info(description, null, { enableHtml: true, progressBar: true });
      Notification.requestPermission().then(function (result) {
      });
      const text = description.replace(/<[^>]*>?/gm, '');
      if (document.hidden) {
        const webNotification = new Notification('Kostango', { body: text });
        webNotification.onclick = () => {
          this.router.navigate(['/record/' + notification.record]);
        };
        this.playAudio();
      }
      // bind click event
      $('#toast-container').unbind('click');
      $('#toast-container').click(() => {
        this.router.navigate(['/record/' + notification.record]);
      });
    });
    combineLatest(this.route.params, this.route.queryParams)
        .subscribe(res => {
          this.search.setValue(this.route.snapshot.queryParams['search']);
          this.store.dispatch(new Sider.Init(this.route.snapshot.params, this.route.snapshot.queryParams)).subscribe(res => {
            this.store.dispatch(new Header.GetUnseen());
          });
        });
    this.search.valueChanges.pipe(debounceTime(800)).pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.updateQueryParams('search', value);
      if (value && ((this.previousSearchValue !== value || this.previousSearchValue == null) || (this.previousSearchValue == value && !this.route.snapshot.queryParams['search']))) {
        try {
          this.previousSearchValue = value;
          const suffix = this.router.isActive("/administration", false) ? 'Users' : 'Records';
          const event = {
            action: 'Search ' + suffix,
            label: 'Search Query [' + value + ']'
          };
          this.gtmService.trackEvents(event, false);
        } catch (error) {
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next("");
    this.destroyed$.complete();
  }

  logout() {
    this.webSocketService.disconnect();
    const workspace = this.localService.getWorkspace();
    this.store.dispatch(new Header.Logout()).subscribe(() => {
      if (!workspace) {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/' + workspace]);
      }
    }, () => { });
  }

  updateQueryParams(name, values) {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    if (!values || !values.length) { values = null }
    const qp = {};
    qp[name] = values;
    this.router.navigate([], {
      //relativeTo: this.route,
      queryParams: qp,
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      //skipLocationChange: true
      // do not trigger navigation
    });
  }

  navigateToAdmin() {
    const page = this.store.snapshot().app.sider.pages.find(p => (p.accessLevel >= Level.Administrate ))
    let entityId = page.entityId ? page.entityId : page.recordEntityId;
    if (typeof page.entityId !== 'undefined' || page.recordEntityId !== 'undefined') {
      this.router.navigate(['administration', entityId, 'field']);
    } else {
      this.router.navigate(['administration', 0, 'user']);
    }
  }

  private recordPageActiveTabIsFieldMany() {
    const state = this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.recordId));
    const tabbedBlocks = state?.entity?.blocks.filter(b => b.isTabbed);
    if (tabbedBlocks?.length && state.tab){
      const activeTab = tabbedBlocks.filter(b => b.title === state.tab)[0];
      if (activeTab?.fields?.length){
        return activeTab.fields.filter(f => f.isMany).length;
      }
    }
    return false;
  }

  showSearch() {
    return (
        this.router.isActive("/records", false) ||
        (this.router.isActive("/administration", false) &&
            this.router.url.includes("/user")) ||
        (this.router.isActive("/record", false) && this.recordPageActiveTabIsFieldMany()) ||
        (this.router.isActive("/home", false) && this.store.snapshot().app.homePage.currentView)
    );
  }

  showBreadcrumb() {
    return (
        this.router.isActive("/records", false) ||
        (this.router.isActive("/record", false) )
    );
  }

  playAudio() {
    const audio = new Audio();
    audio.src = "../../assets/audio/button_tiny.mp3";
    audio.load();
    audio.play();
  }

  getCurrentRoute() {
    this.routeIsRecord = this.router.url.includes('/record/');
    this.routeIsHome = this.router.url.includes('home');
    this.routeIsRecords = this.router.url.includes('/records/');

  }

  toggleSider() {
    this.localService.setSiderVisibility(1);
  }
  switchSpace(spaceId) {
    this.store.dispatch(new Sider.SwitchSpace(spaceId,true));
  }
  isSpaceActive(spaceId): boolean{
    return this.localService.getSpace() == spaceId
  }

  isLinkActive(url): boolean {
    return this.router.isActive(url, {
      paths: 'exact',
      queryParams: 'ignored',
      fragment: 'ignored',
      matrixParams: 'ignored'
    });
  }

  getRouterLink(page) {
    return page.recordId ? ('/record/' + page.recordId) : ('/records/' + page.entityId);
  }
  isPagesetLinkActive(pages: []) {
    return pages.some((page: any) => this.isLinkActive(this.getRouterLink(page)));
  }
  getSelectedPageInPageset(pages: []) {
    return pages.find((page: any) => this.isLinkActive(this.getRouterLink(page)));
  }


  switchTheme(theme) {
    this.localService.setTheme(theme)
  }

  onPageSetMouseOver(ref: any) {
    if (!this.isPageSetActive) {
      ref.open();
      this.isPageSetActive = !this.isPageSetActive;
    }
  }

  onPageSetMouseLeave(ref: any) {
    if (this.isPageSetActive) {
      setTimeout(() => {
        ref.close();
        this.isPageSetActive = !this.isPageSetActive;
      }, 400);
    }
  }
}
