import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  HostListener
} from '@angular/core';
import DOMPurify from 'dompurify';
import Editor from '@toast-ui/editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import '@toast-ui/editor/dist/i18n/fr-fr';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {translate, TranslocoDirective} from "@jsverse/transloco";
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import {capturePhoto, startCamera, stopCamera, isWindowsTablet} from '../../app.utils'


@Component({
    selector: 'editor',
    templateUrl: './editor.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective]
})
export class EditorComponent implements OnDestroy, AfterViewInit, OnChanges {

  @Input() focus = false;
  @Input() isForm = false;
  @Input() value!: string | null;


  @Output() valueChanges = new EventEmitter<string | null>();
  @Output() tabKeyPress = new EventEmitter();

  @ViewChild('editorWrapper', {static: true}) editorWrapper!: ElementRef;
  @ViewChild('ModalRef') modalRef: ElementRef;

  readonly destroyed = new Subject();

  private editor?: Editor;
  private videoElement: any;

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    const cameraIcon = document.querySelector('.toastui-editor-toolbar-icons.camera');
    const imageIcon = document.querySelector('.toastui-editor-toolbar-icons.image');

    if (cameraIcon && cameraIcon.contains(event.target as Node)) {
      this.openUploadModal();
    } else if (imageIcon && imageIcon.contains(event.target as Node)) {
      const selectFileButton = document.querySelector('.toastui-editor-file-select-button');
      selectFileButton.classList.add('truncate');
      selectFileButton.textContent = translate('sélectionner une photo');
      selectFileButton.setAttribute('title', translate('sélectionner une photo'));
    }
  }

  constructor(private readonly ngZone: NgZone,private translocoLocaleService: TranslocoLocaleService, public modalService: NgbModal) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focus && !changes.focus.firstChange && this.focus && this.editor) {
      setTimeout(() => {
        this.editor.focus();
        this.editor.moveCursorToEnd();
      });
    }
    if (
      changes.value &&
      // if the editor is not defined yet the value is set to the most recent one during the initialisation of the editor
      this.editor &&
      this.value !== this.editor.getMarkdown()
    ) {
      this.editor.setMarkdown(this.value ?? '');
    }
  }

  ngAfterViewInit() {
    // to make the (computeheavy) initialisation of the editor non blocking
    this.initializeEditor()
    if ((this.focus && this.isForm) || !this.isForm) {
      setTimeout(() => {
        this.editor.focus();
        this.editor.moveCursorToEnd();
      });
    }
  }

  private initializeEditor() {
    // Use another subject between the ValueChangesEmitter and the editors change-Event
    // to debounce the (slow) calls to getMarkdown()
    const valueChanges = new Subject();
    valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.valueChanges.emit(this.editor!.getMarkdown());
      });
    //add Kostango colors
    const colorSyntaxOptions = {
        preset: ['#b8d935', '#e8c444', '#4fc9da', '#4f55da', '#31771c', '#f06445', '#9370db', '#666666', '#0094b6', '#d84280', '#2c3e50', '#004d40', '#795548', '#e67e22']
    };
    // run outside the zone to improve performance
    this.ngZone.runOutsideAngular(() => {
      // Initialise editor
      this.editor = new Editor({
        el: this.editorWrapper.nativeElement,
        initialValue: this.value ?? '',
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical',
        height: `auto`,
        language: this.translocoLocaleService.getLocale(),
        plugins: [[colorSyntax, colorSyntaxOptions]],
        toolbarItems: [
          [],
          [],
          [],
          ['bold'],
        ],
        customHTMLSanitizer: (html: string) =>
          DOMPurify.sanitize(html),
      });

      // Insert custom toolbar item for header
      this.editor.insertToolbarItem({ groupIndex: 0, itemIndex: 0 }, {
        name: 'heading',
        tooltip: translate('En-têtes'),
        text: '',  // No text, we're using the icon
        className: 'toastui-editor-toolbar-icons heading fa fa-header',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 0, itemIndex: 1 }, {
        name: 'bold',
        tooltip: translate('Gras'),
        command: 'bold',
        text: "",
        className: 'toastui-editor-toolbar-icons Gras fa fa-bold',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 0, itemIndex: 2 }, {
        name: 'italic',
        tooltip: translate('Italique'),
        command: 'italic',
        text: "",
        className: 'toastui-editor-toolbar-icons italic fa fa-italic',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 0, itemIndex: 3 }, {
        name: 'strike',
        tooltip: translate('Barré'),
        command: 'strike',
        text: "",
        className: 'toastui-editor-toolbar-icons strike fa fa-strikethrough',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 1, itemIndex: 0 }, {
        name: 'thematicBreak',
        tooltip: translate('Ligne'),
        command: 'thematicBreak',
        text: "",
        className: 'toastui-editor-toolbar-icons hrline fa fa-horizontal-rule',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 1, itemIndex: 1 }, {
        name: 'blockQuote',
        tooltip: translate('Citation'),
        text: "",
        className: 'toastui-editor-toolbar-icons quote fa fa-quote-left',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 2, itemIndex: 0 }, {
        name: 'bulletList',
        tooltip: translate('Liste non-ordonnée'),
        command: 'bulletList',
        text: "",
        className: 'toastui-editor-toolbar-icons bullet-list fa fa-list-ul',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 2, itemIndex: 1 }, {
        name: 'orderedList',
        tooltip: translate('Liste ordonnée'),
        command: 'orderedList',
        text: "",
        className: 'toastui-editor-toolbar-icons ordered-list fa fa-list-ol',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 2, itemIndex: 2 }, {
        name: 'taskList',
        tooltip: translate('Tâche'),
        command: 'taskList',
        text: "",
        className: 'toastui-editor-toolbar-icons task-list fa fa-square-check',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 2, itemIndex: 3 }, {
        name: 'outdent',
        tooltip: translate('Sortir'),
        command: 'outdent',
        className: 'toastui-editor-toolbar-icons outdent fa fa-outdent',

        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 3, itemIndex: 0 }, {
        name: 'table',
        tooltip: translate('insérer un tableau'),
        text: "",
        className: 'toastui-editor-toolbar-icons table fa fa-table',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 3, itemIndex: 1 }, {
        name: 'link',
        tooltip: translate('insérer un lien'),
        text: "",
        className: 'toastui-editor-toolbar-icons link fa fa-link',
        style: { backgroundImage: 'none' },
      });

      this.editor.insertToolbarItem({ groupIndex: 3, itemIndex: 2 }, {
        name: 'image',
        tooltip: translate('insérer une image'),
        text: "",
        className: 'toastui-editor-toolbar-icons image fa fa-image',
        style: { backgroundImage: 'none' },
      });

      if (isWindowsTablet()) {
        this.editor.insertToolbarItem({ groupIndex: 3, itemIndex: 3 }, {
          name: 'camera',
          tooltip: translate('Prendre une photo'),
          text: "",
          className: 'toastui-editor-toolbar-icons camera fa fa-camera',
          style: { backgroundImage: 'none' },
        });
      }

      document.querySelectorAll('.toastui-editor-mode-switch .tab-item')[1].innerHTML = 'Visuel';
      const quoteIcon = document.querySelector('.toastui-editor-toolbar-icons.quote');

      this.editor.on('change', (evt) => {
        this.ngZone.run(() => valueChanges.next(""));
      });

      this.editor.getEditorElements().wwEditor.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          event.preventDefault();
          this.tabKeyPress.emit();
        }
      });
      // fix html issues when pasting
      this.editor.getEditorElements().wwEditor.addEventListener('paste', (event: ClipboardEvent) => {
        const clipboardData = event.clipboardData || (window as any).clipboardData;
        const pastedText = clipboardData.getData('text/html') || clipboardData.getData('Text');
        const doc = new DOMParser().parseFromString(pastedText, 'text/html');
        const isHTML = doc.body.innerHTML !== pastedText
        if (isHTML) {
          this.editor.setHTML(doc.body.innerHTML);
        }
      });

      quoteIcon.addEventListener('click', () => {
        const isMarkdownMode = this.editor.isMarkdownMode();
        if (isMarkdownMode) {
          this.editor.exec('blockQuote');
        } else {
          const markdownContent = this.editor.getMarkdown();
          const hasBlockquote = markdownContent.includes('> ');

          if (hasBlockquote) {
            const newContent = markdownContent.replace(/^> /gm, '');
            this.editor.setMarkdown(newContent);
          } else {
            const newContent = `> ${markdownContent}`;
            this.editor.setMarkdown(newContent);
          }
        }
      });
    });
  }

  capturePhoto(modal) {
    const photo = capturePhoto(this.videoElement);
    this.editor.exec('addImage', { altText: 'image', imageUrl: photo });
    modal.close();
  }

  openUploadModal() {
    const modelRef = this.modalService.open(this.modalRef, {size: 'lg', centered: true});
    this.videoElement = document.getElementById('video');
    startCamera(this.videoElement);
    modelRef.closed.subscribe(() => {
      stopCamera(this.videoElement?.srcObject);
    });
    modelRef.dismissed.subscribe(() => {
      stopCamera(this.videoElement?.srcObject);
    });
  }

  ngOnDestroy() {
    this.destroyed.next("");
  }
}
