import {Injectable} from '@angular/core';
import {Injector} from '@angular/core';
import {BaseService} from './base.service';
import * as _ from 'lodash';
import {map, mergeMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {computePageSize, fieldTypeRecord, getMode, hasGroupby} from '../app.utils';


@Injectable({
  providedIn: 'root'
})
export class RecordService extends BaseService {
  key = 'records';

  constructor(public injector: Injector) {
    super(injector);
  }

  public getExcelUrl(entityId, queryParams) {
    const url = this.envService.apiEndpoint + this.key + '/?';
    const qp = _.cloneDeep(queryParams);
    qp['entity'] = entityId;
    qp['extension'] = 'xlsx';
    const queryString = Object.keys(qp).map(key => {
      if (Array.isArray(qp[key])) {
        return qp[key].map(val => key + '=' + val).join('&');
      }
      return key + '=' + qp[key];
    }).join('&');
    return url + queryString;
  }

  public retrieveRecordsCount(params: object, propagations: string[] = []): any {
    return super.fieldToService('entity').retrieveMetadata(params['entity']).pipe(
      mergeMap((entity: any) => {
        const mode = getMode(params['mode']);
        let qpCount = _.cloneDeep(params);
        if (mode === 'calendar') {
          _.isArray(qpCount[entity.endingDateField.name]) ? qpCount[entity.endingDateField.name].unshift('notnull') : qpCount[entity.endingDateField.name] = 'notnull';
          if (entity.startingDateField && entity.startingDateField.name) {
            if (_.isArray(qpCount[entity.startingDateField.name])) {
              qpCount[entity.startingDateField.name].unshift('notnull');
            } else {
              qpCount[entity.startingDateField.name] = 'notnull';
            }
          }
        } else if (mode === 'timeline') {
          qpCount["any"] = [];
          if (entity.startingDateField) qpCount["any"].push(entity.startingDateField.name)
          if (entity.endingDateField) qpCount["any"].push(entity.endingDateField.name)
        }
        try {
          delete qpCount['cursor'];
          delete qpCount['page_size'];
        } catch (err) {
        }
        return super.retrieveObjects(qpCount, propagations).pipe(map((records$: any) => records$.length));
      })
    );
  }

  public retrieveUnscheduledCount(params: object, propagations: string[] = []): any {
    return super.fieldToService('entity').retrieveMetadata(params['entity']).pipe(
      mergeMap((entity: any) => {
        const mode = getMode(params['mode']);
        if (mode !== 'calendar' && mode !== 'timeline'){
          return of(0);
        }
        const qpUnscheduledCount = _.cloneDeep(params);
        const endingDateField = entity.endingDateField;
        const startingDateField = entity.startingDateField
        if (mode === 'calendar') {
          qpUnscheduledCount['extension'] = 'calendar';
        } else {
          qpUnscheduledCount[startingDateField?.name] = null;
          qpUnscheduledCount[endingDateField?.name] = null;
        }
        delete qpUnscheduledCount['cursor'];
        delete qpUnscheduledCount['page_size'];
        return super.retrieveObjects(qpUnscheduledCount, propagations).pipe(map((records$: any) => records$.length));
      })
    );
  }

  public retrieveDashboardRecords(params: object = {}) {
    params['extension'] = 'reporting';
    const url = this.envService.apiEndpoint + this.key + '/?';
    return this.http.get(url, {params});
  }

  public retrieveCardsRecords(params: object, propagations: string[] = [], page_size: number, cursor: number, activeFields: Function,expandMany:boolean = true): any {
    params['cursor'] = cursor;
    params['page_size'] = page_size;
    return this.retrieveRecordsPropagated(params, propagations, activeFields,expandMany);
  }

  public retrieveTimelineRecords(params: object, propagations: string[] = [], page_size: number, cursor: number, activeFields: Function, expandMany: boolean = true): any {
    return super.fieldToService('entity').retrieveMetadata(params['entity']).pipe(mergeMap((entity: any) => {
      if (!entity.startingDateField && !entity.endingDateField) return of();
      params['cursor'] = cursor;
      params['page_size'] = page_size;
      params['any'] = []
      if (entity.startingDateField) params["any"].push(entity.startingDateField.name)
      if (entity.endingDateField) params["any"].push(entity.endingDateField.name)
      return this.retrieveRecordsPropagated(params, propagations, activeFields,expandMany);
    }));
  }

  public retrieveCalenderRecords(params: object, calendarStart: Date, calendarEnd: Date, activeFields?: Function, expandMany: boolean = true): any {
    return super.fieldToService('entity').retrieveMetadataWithMirrors(params['entity']).pipe(mergeMap((entity: any) => {
      params['extension'] = 'calendar';
      if (!entity.endingDateField || !calendarStart || !calendarEnd)
         return this.retrieveObjects(params,[]).pipe(map((res: any) => {return {...res, data: []}}));
      params['calendarStart'] = calendarStart.toISOString();
      params['calendarEnd'] = calendarEnd.toISOString();

      return this.retrieveObjects(params,[])
    }));
  }

  public retrieveRecords(params: object, options:any, propagations: string[] = [], extra: string[] = [], expandMany:boolean = false): any {
    let pageSize = 17;
    params = extra.length ? {...params, ...{extra: extra.join(',')}} : params;
    return super.fieldToService('entity').retrieveMetadata(params['entity'], []).pipe(mergeMap((entity: any) => {
      const mode = getMode(params['mode']);
      switch (mode) {
        case 'cards':
          pageSize = (options?.qp_page || 1) * computePageSize(entity.cols, entity.height + 8);
          return this.retrieveCardsRecords(params, propagations,pageSize, options.cursor, () => {
            return !params['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f =>f.isExpanded && (expandMany || !fieldTypeRecord.includes(f.type))).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded && (expandMany || !fieldTypeRecord.includes(f.type)) || hasGroupby(entity, f)).map(f => f.id).join(',');
          });
        case 'grid':
          pageSize = (options?.qp_page || 1) * computePageSize(entity.cols, 80);
          return this.retrieveCardsRecords(params, propagations, pageSize, options.cursor, () => {
            return !params['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isActiveTable).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isActiveTable || hasGroupby(entity, f)).map(f => f.id).join(',');
          });
        case 'timeline':
          pageSize = (options?.qp_page || 1) * computePageSize(12, 75);
          return this.retrieveTimelineRecords(params, propagations, pageSize, options.cursor, () => {
            return !params['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded && (expandMany || !fieldTypeRecord.includes(f.type))).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded && (expandMany || !fieldTypeRecord.includes(f.type)) || hasGroupby(entity, f)).map(f => f.id).join(',');
          }, expandMany);
        case 'calendar':
          return this.retrieveCalenderRecords(params, options.calendarStart, options.calendarEnd, () => {
            return entity.ownerField + ',';
          });
        case 'dashboard':
          return this.retrieveDashboardRecords(params);
        default:
          return of();
      }
    }));
  }

  public retrieveUnscheduledRecords(qp: object, entity: any, cursor: number) {
    const computedPageSize = computePageSize(12, 45);
    qp['page_size'] = computedPageSize;
    qp['cursor'] = cursor;
    qp['entity'] = entity.id;

    if (qp['mode'] === 'calendar') {
      qp['extension'] = 'calendar';

      return this.retrieveObjects(qp)
    } else {
      const endingDateField = entity.endingDateField;
      const startingDateField = entity.startingDateField;

      if (startingDateField) qp[startingDateField.name] = null;
      if (endingDateField) qp[endingDateField.name] = null;

      return this.retrieveObjects(qp)
    }
  }

  private retrieveRecordsPropagated(params: object, propagations: string[] = [], activeFields?: Function, expandMany: boolean = false): any {
    let fieldIds = activeFields();
    propagations = ['value.' + fieldIds, 'tags'].concat(propagations);
    return super.retrieveObjects(params, propagations).pipe(
      mergeMap((res: any) => {
        return of(res);
      })
    );
  }
}

