import {Action, State, Store} from '@ngxs/store';
import {Header, HomePage} from './app.actions';
import {ViewService} from '../services/view.service';
import {LocalService} from '../services/local.service';
import {delayWhen, map, mergeMap, tap,catchError} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {RecordService} from '../services/record.service';
import * as _ from 'lodash';
import {UserService} from '../services/user.service';
import {computePageSize, getMode, groupRecords, hasGroupby, mutateState} from '../app.utils';
import {EntityService} from '../services/entity.service';
import {Injectable} from '@angular/core';
import { param } from 'cypress/types/jquery';

@State({
  name: 'homePage',
  defaults: {
    views: null,
    records: {},
    currentCursor: -1,
    unscheduledRecords: {},
    unscheduledCount: null,
    count: null,
    isUnscheduledReady: true,
    calendarStart: null,
    calendarEnd: null,
    user: null,
    isListReady: false,
    entity: null,
    currentView: null,
    latestResult: null,
    queryParams: {},
    total: null,
    nextCursor: null,
    unscheduledNextCursor: null,
    allElementsLoaded: false,
  }
})
@Injectable()
export class HomePageState {

  constructor(private localService: LocalService,
              private userService: UserService,
              private viewService: ViewService,
              private entityService: EntityService,
              private recordService: RecordService,
              private store: Store) { }

  @Action(HomePage.Init, { cancelUncompleted: true })
  init(ctx, {queryParams, ready = false}: HomePage.Init) {
    const space = this.localService.getSpace();
    const user = this.localService.getUser();

    return this.viewService.retrieveObjects({space, user}, [], ['user']).pipe(
      mergeMap((response: any) => {
        const {extra: {user}, data: views} = response;
        const viewId = user.saves.home ? user.saves.home[space] : null;

        mutateState(ctx, draft => {
          draft.user = user;
          draft.queryParams = queryParams;

          if (!draft.currentView || draft.currentView.id !== +viewId) {
            draft.views = null;
            draft.entity = null;
            draft.currentView = null;
            draft.isListReady = false;
            draft.records = {};
          }
        });

        if (!views.length) {
          mutateState(ctx, draft => {
            draft.views = [];
            draft.records = {};
            draft.isListReady = true;
          });
          return of();
        }

        return forkJoin(views.map(view =>
          this.entityService.retrieveMetadata(view.entity).pipe(
            mergeMap((entity: any) => {
              view.entity = entity;
              const qp = {..._.cloneDeep(view.value), entity: entity.id};
              qp['page_size'] = 0;

              return this.recordService.retrieveObjects(qp).pipe(
                tap((records: any) => {
                  view.count = records.count;
                })
              );
            })
          )
        )).pipe(
          mergeMap(() => {
            mutateState(ctx, draft => {
              draft.views = _.chain(views).groupBy('entity.plural').value();
            });

            if (viewId) {
              const selectedView = views.find(v => v.id == viewId);
              return selectedView
                ? this.store.dispatch(new HomePage.RetrieveRecords(selectedView, queryParams, -1, ready))
                : of([]);
            }

            mutateState(ctx, draft => {
              draft.records = {};
              draft.isListReady = true;
            });
            return of([]);
          })
        );
      })
    );
  }

  @Action(HomePage.UploadPicture)
  uploadPicture(ctx, { file }) {
    return this.userService.updateObject({ id: this.localService.getUser(), picture: file }, ['space']).pipe(tap((user: any) => {
      this.store.dispatch(new Header.UpdateUserPicture(user.picture));
      mutateState(ctx, draft => {
        draft.user = user;
      });
    }));
  }

  SaveUserView(ctx, currentView) {
    // saving selected view to user "saves"
    try {

      let userSaves = _.cloneDeep(ctx.getState().user.saves);
      const spaceId = this.localService.getSpace();
      userSaves.home[spaceId] = currentView.id;

      mutateState(ctx, draft => {
        draft.user.saves.home[spaceId] = currentView.id;
      });
      return this.userService.updateObject({
        id: this.localService.getUser(),
        saves: userSaves
      });
    } catch (err) {
      return this.userService.updateObject({
        id: this.localService.getUser(),
        saves: { home: {}, records: {}, record: {} }
      });
    }
  }

  @Action(HomePage.RetrieveRecords, { cancelUncompleted: true })
  retrieveRecords(ctx, { currentView, queryParams, cursor, ready = false }) {
    const mode = getMode(currentView.value['mode']);
    const entityId = currentView.entity.id

    mutateState(ctx, draft => {
      draft.isListReady = (cursor !== -1 && (mode === 'cards' || mode === 'timeline')) || ready || currentView.id === draft.currentView?.id;

      if (cursor === -1 && !ready && currentView.id !== draft.currentView?.id) {
        draft.currentPage = -1;
        draft.records = {};
        draft.records[currentView.id] = [];
      }
      if (cursor !== -1 && !ready){
        draft.currentPage = cursor
      }
      draft.currentView = currentView;
      draft.count = draft.currentView.count;
    });

    const qp = {..._.cloneDeep(currentView.value), ...queryParams, 'entity': entityId};

    let entity$ = this.entityService.retrieveMetadata(entityId);
    return entity$.pipe(
      mergeMap((entity: any) => {
        mutateState(ctx, draft => {
          draft.entity = entity;
        });

        const options = {
          'cursor': cursor,
          'currentCursor': ready ? ctx.getState().currentCursor: -1,
          'calendarStart': ctx.getState().calendarStart,
          'calendarEnd': ctx.getState().calendarEnd
        };

        if (mode === 'calendar') {
          return this.recordService.retrieveCalenderRecords(qp, options['calendarStart'], options['calendarEnd'], () => {
            return entity.ownerField + ',';
          });
        }
        if (mode === 'dashboard') {
          return this.recordService.retrieveDashboardRecords(qp);
        }
        if (mode === 'timeline') {
          let pageSize =  computePageSize(12, 75);
          return this.recordService.retrieveTimelineRecords(qp, ['space'], pageSize, options.cursor, (r) => {
            return !qp['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded).map(f => f.id).join(',') :
              _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded || hasGroupby(entity, f)).map(f => f.id).join(',');
          })
        }
        let pageSize = computePageSize(entity.cols, entity.height + 8)
        return this.recordService.retrieveCardsRecords(qp, ['space'], pageSize, options.cursor, (r) => {
          return !qp['groupby'] ? _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded).map(f => f.id).join(',') :
            _.filter(_.flatten(_.map(entity.blocks, (b)=> b.fields)), f => f.isExpanded || hasGroupby(entity, f)).map(f => f.id).join(',');
        })
      }),
      mergeMap((records: any) => {
        mutateState(ctx, draft => {
          if (ready){
            draft.records[currentView.id] = []
          }
          if (['dashboard', 'calendar'].includes(mode)) {
            draft.records[currentView.id] = records.data;
          } else {
            const distinctRecords = _.uniqBy(_.concat(draft.records[currentView.id], records.data), (r: any) => r.id);
            draft.records[currentView.id] = !qp['groupby'] ? distinctRecords : groupRecords(draft.entity, distinctRecords, qp);
          }
          draft.allElementsLoaded = !records.hasNext;
          draft.isListReady = true;
          draft.nextCursor = records.nextCursor;
        });

        // unscheduled count
        const unscheduledCountQp = _.cloneDeep(currentView.value);
        const endingDateField = currentView.entity.endingDateField;
        const startingDateField = currentView.entity.startingDateField;

        if (endingDateField) unscheduledCountQp[endingDateField.name] = null;
        if (startingDateField) unscheduledCountQp[startingDateField.name] = null;
        unscheduledCountQp['entity'] = entityId;

        this.recordService.retrieveObjects(unscheduledCountQp).pipe(
          map((records$: any) => {
            mutateState(ctx, draft => {
              draft.unscheduledCount = records$.length;
            });
          })
        ).subscribe()

        return this.SaveUserView(ctx, currentView);
      })
    );
  }

  @Action(HomePage.ChangeCalendarDates)
  changeCalendarDates(ctx, { startDate, endDate, queryParams }) {
    mutateState(ctx, draft => {
      draft.calendarStart = startDate;
      draft.calendarEnd = endDate;
    });
    return this.store.dispatch(new HomePage.RetrieveRecords(ctx.getState().currentView, queryParams, -1));
  }

  @Action(HomePage.ClearView)
  clearView(ctx, { viewId }) {
    if (ctx.getState().currentView && ctx.getState().currentView.id === viewId) {
      this.SaveUserView(ctx, null).pipe(
        mergeMap((user: any) => {
        mutateState(ctx, draft => {
          draft.user.saves.home = user.saves.home;
          draft.currentView = null;
          draft.records = {};
          draft.entity = null;
        });
        return this.store.dispatch(new HomePage.Init({}));
      }));
    }
    else {
      this.store.dispatch(new HomePage.Init({}));
    }
  }

  @Action(HomePage.UpdateRecordFields)
  updateRecordFields(ctx, { record, fieldValueMapping }) {
    let newValue = {};
    Object.keys(fieldValueMapping).forEach((key) => {
      newValue[key] = fieldValueMapping[key];
    });
    return this.recordService.updateObject({ id: record.id, value: newValue }, ['value', 'tags', 'fixedCheckpoint']);
  }

  @Action(HomePage.RetrieveUnscheduledRecords)
  retrieveUnscheduledRecords(ctx, { currentView, cursor }) {
    mutateState(ctx, draft => {
      draft.isUnscheduledReady = false;
      if (cursor === -1) {
        draft.unscheduledRecords[currentView.id] = [];
      }
    });
    let qp = _.cloneDeep(currentView.value);
    const entity = currentView.entity;
    const UnscheduledCalenderRecords$ = this.recordService.retrieveUnscheduledRecords(qp, entity, cursor);
    return UnscheduledCalenderRecords$.pipe(tap((records: any) => {
        mutateState(ctx, draft => {
          if (draft.unscheduledRecords[currentView.id]) {
            draft.unscheduledRecords[currentView.id] = draft.unscheduledRecords[currentView.id].concat(records.data);
          } else {
            draft.unscheduledRecords[currentView.id] = records.data;
          }
          draft.unscheduledRecords [currentView.id] = _.uniqBy(draft.unscheduledRecords [currentView.id], (r) => {
            return r['id'.toString()];
          });
          draft.isUnscheduledReady = true;
          draft.unscheduledNextCursor = records.nextCursor;
        });
      }));
  }
}
