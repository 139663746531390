import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import { FilterByPipe } from 'ngx-pipes';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'choice',
    templateUrl: './choice.component.html',
    styleUrls: ['./choice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, FormsModule, AutofocusDirective]
})
export class ChoiceComponent implements OnInit {

  public _choice;
  @Input()
  set choice(val) {
    this._choice = _.cloneDeep(val);
  }
  get choice() {
    return this._choice;
  }

  @Input() deleteLoading;
  @Input() canEdit;
  @Input() lang: string;
  @Input() titleNameTranslation = 'title';
  @Input() hasNote=false;
  @Input() note;
  @Output() public noteChange = new EventEmitter();

  @Output() public choiceTitleChange = new EventEmitter();
  @Output() public choiceDelete = new EventEmitter();

  choiceMode = 'read';
  oldChoiceTitle;

  constructor() {}

  ngOnInit() {
  }
  onNoteChange(evt) {

    this.noteChange.emit(evt.target.value);
  }

}
