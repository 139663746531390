import {UserService} from '../../services/user.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output
} from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import {UntypedFormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RecordService} from 'src/app/services/record.service';
import {SpaceService} from "../../services/space.service";
import * as _ from 'lodash';
import {ChoiceService} from 'src/app/services/choice.service';
import {LocalService} from "src/app/services/local.service";
import {FieldValueToClassPipe} from '../../pipes/field-value-to-class.pipe';
import {SpecialTitleCasePipe} from '../../pipes/special-title.pipe';
import {NgArrayPipesModule} from 'ngx-pipes';
import {LoaderComponent} from '../loader/loader.component';
import {DatepickerComponent} from '../datepicker/datepicker.component';
import {AutocompleteComponent} from '../autocomplete/autocomplete.component';
import {IconComponent} from '../icon/icon.component';
import {NgClass, NgIf, NgFor} from '@angular/common';
import {TranslocoDirective} from '@jsverse/transloco';
import {Store} from "@ngxs/store";

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, NgClass, NgIf, IconComponent, NgFor, AutocompleteComponent, FormsModule, ReactiveFormsModule, DatepickerComponent, LoaderComponent, NgArrayPipesModule, SpecialTitleCasePipe, FieldValueToClassPipe]
})
export class FilterComponent implements OnInit, OnChanges {
  readonly EMPTY_VALUE = {id: 'null'}
  private _values: any = [];

  @Input() field;
  @Input() entity;
  @Input() expanded;

  @Input()
  set values(vs) {
    if (_.isEqual(vs, this.lastVs)) return;
    this.lastVs = _.cloneDeep(vs);

    switch (this.field.type) {
      case 'Number':
        this.min.setValue(vs.length === 2 ? vs[0] : null);
        this.max.setValue(vs.length === 2 ? vs[1] : null);
        break;
      case 'SingleSelect':
      case 'MultiSelect':
        this.retrieveObjects(vs, this.choiceService);
        break;
      case 'CreatedBy':
      case 'User':
      case 'E-Signature':
      case 'Users':
        this.retrieveObjects(vs, this.userService);
        break;
      case 'OneToOne':
      case 'InverseOneToOne':
      case 'OneToMany':
      case 'InverseOneToMany':
      case 'ManyToMany':
      case 'InverseManyToMany':
      case 'Checklist':
        this.retrieveObjects(vs, this.recordService);
        break;
      case 'LastModifiedAt':
      case 'CreatedAt':
      case 'Date':
      case 'DateTime':
        this._values = vs;
        break;
      case 'Tags':
      case 'Space':
      case 'Spaces':
        this._values = vs.map(Number);
        break;
      case 'Checkbox':
        this._values = vs.map(v => v === 'true');
        break;
    }
    if (vs.length === 0) this._values.length = 0;
  }
  get values() {
    return this._values;
  }

  @Output()
  filterChange = new EventEmitter();


  min = new UntypedFormControl();
  max = new UntypedFormControl();
  lastVs = null;
  choices = [];
  checkbox = [
    true,
    false
  ];

  constructor(public recordService: RecordService,
              public choiceService: ChoiceService,
              public userService: UserService,
              public changeDetector: ChangeDetectorRef,
              public store: Store,
  ) {
  }

  ngOnInit() {
    this.expanded = false;
    this.subscribeMinMax();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.expanded) this.getChoices(this.field);
  }

  isFilterActive() {
    return this.values.length || this.min.value || this.max.value;
  }

  removeValue(value) {
    this._values = this.values.filter(v => value !== null ? (v !== null ? v.id != value.id : v !== value) : v !== value);
    this.filterChange.emit(this.values.map(v => v !== null ? v.id : "null"));
  }

  retrieveObjects(ids, service) {
    let observables$ = ids.map(value => value !== "null" ? service.retrieveObject(value) : of(this.EMPTY_VALUE))
    forkJoin(observables$).subscribe(objects => {
      this._values = objects;
      this.changeDetector.detectChanges();
    });
  }

  onAutocompleteSelectItem(added) {
    if (Array.isArray(added)) {
      added.forEach(item => {
        if (this.values.map(obj => obj !== null ? obj.id : obj).indexOf(item !== null ? item.id : item) > -1) {
          return;
        }
        this._values.push(item);
      });
    } else {
      if (this.values.map(obj => obj !== null ? obj.id : obj).indexOf(added !== null ? added.id : added) > -1) {
        return;
      }
      this._values.push(added);
    }
    // remove item when click on it
    this._values = this._values.filter(item => {
      if (Array.isArray(added)) {
        return added.some(a => a.id === item.id || a === item);
      } else {
        return added.id === item.id || added === item;
      }
    });

    this.filterChange.emit(_.uniq(this.values.map(u => u !== null ? u.id : 'null')));
  }

  exists(choice) {
    return this.values.map(c => c !== 'null' ? c.id : 'null').indexOf(choice !== 'null' ? choice.id : 'null') > -1;
  }

  singleSelectSelect(value) {
    if (this.values.map(v => v !== 'null' ? v.id : 'null').indexOf(value !== 'null' ? value.id : 'null') > -1) {
      this._values = this.values.filter(v => value !== 'null' ? (v !== 'null' ? v.id != value.id : v !== value) : v !== value);
    } else {
      this._values.push(value);
    }
    this.filterChange.emit(this.values.map(v => v !== 'null' ? v.id : 'null'));
  }

  getChoices(field) {
    if ((field.type === 'SingleSelect' || field.type === 'MultiSelect') && !field.hasTooManyChoices) {
      this.choiceService.retrieveObjects({choiceset: field.choiceset})
        .pipe(
          map((response: any) => response.data || [])
        )
        .subscribe(choices => {
          if (!field.isMandatory) {
            choices.push('null');
          }
          this.choices = choices;
          this.changeDetector.detectChanges();
        });
    }
  }

  getSpaces() : any {
    const spaces = this.store.selectSnapshot(state => state.app.sider.flatSpaces);
    return _.filter(spaces, (s) => this.entity.spaces.includes(s.id));
  }

  checkboxSelect(value) {
    if (this.values.indexOf(value) > -1) {
      this._values = this.values.filter(v => v !== value);
    } else {
      this._values.push(value);
    }
    this.filterChange.emit(this.values);
  }

  valueSelected(value) {
    return this.values.includes(value);
  }

  subscribeMinMax() {
    this.min.valueChanges.pipe(distinctUntilChanged(), debounceTime(800)).subscribe(() => {
      this.emitMinMax();
    });
    this.max.valueChanges.pipe(distinctUntilChanged(), debounceTime(800)).subscribe(() => {
      this.emitMinMax();
    });
  }

  emitMinMax() {
    let min = [null, null, ''].indexOf(this.min.value) > -1 ? null : this.min.value;
    let max = [null, null, ''].indexOf(this.max.value) > -1 ? null : this.max.value;
    if (!min && !max) {
      this.filterChange.emit([]);
    } else {
      this.filterChange.emit([min, max]);
    }
  }

  tagSelect(tagId) {
    if (this._values.find(id => id === tagId)) {
      this._values = this._values.filter(id => id !== tagId);
    } else {
      this._values.push(tagId);
    }
    this.filterChange.emit(this._values);
  }

  spaceSelect(spaceId) {
    if (this._values.find(id => id === spaceId)) {
      this._values = this._values.filter(id => id !== spaceId);
    } else {
      this._values.push(spaceId);
    }
    this.filterChange.emit(this._values);
  }

  unselect(event) {
    event.stopPropagation()
    if (this.field.type == 'Number') {
      this.min.setValue(null);
      this.max.setValue(null);
      this.emitMinMax();
    } else {
      this.filterChange.emit([]);
    }
    this.expanded = false;
  }
}
