import {
  AfterViewInit, ChangeDetectionStrategy,
  Component,
  ComponentRef, EventEmitter, Injector,
  Input,
  OnChanges, OnDestroy, OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {DynamicCardFactoryProvider} from "./card.factory";
import {translate} from "@jsverse/transloco"
import * as _ from "lodash";
import {applyApplicationStyle, fieldTypeRecord} from "../../app.utils";
import {Store} from '@ngxs/store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { RthighlightDirective } from '../../directives/rthighlight.directive';
import { NgIf } from '@angular/common';


@Component({
    selector: 'card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [NgIf, RthighlightDirective, RouterLink]
})

export class CardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  public entity: any;
  @Input()
  public record: any;

  openInModal: boolean;

  @ViewChild('dynamicCardHolder', {read: ViewContainerRef, static: false})
  public vcRef: ViewContainerRef;
  private compRef: ComponentRef<any>;

  public childContext: object = {};
  private wasChildInitialized = false;
  @Output() public cardClick = new EventEmitter();

  constructor(
      private factoryProvider: DynamicCardFactoryProvider,
      private injector:Injector,
      private store:Store,
      private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const fixingRecordInAudit = this.record.fixedCheckpoint && !!this.store.selectSnapshot((state) => state.app.recordPage.getRecord(this.record['parentRecords'][0])).record
    this.openInModal = this.route.snapshot.outlet === "modal" || this.entity.isDetailPageInModal || fixingRecordInAudit;
  }

  ngOnChanges(): void {
    const staticize = this.staticize(this.record, this.entity);
    if (staticize.hasOwnProperty('record') && staticize.hasOwnProperty('entity')) {
      this.childContext['record'] = staticize['record'];
      this.childContext['entity'] = staticize['entity'];
      this.childContext['onRecordClick'] = ($event) => {
        $event.stopPropagation();
      }
      if (this.wasChildInitialized && this.compRef) {
        this.bindContext();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.vcRef){
      if (!this.entity.template){
        throw Error('A Template must be provided to render the dynamic component');
      }
      if (this.vcRef.length){
        this.vcRef.clear();
      }
      this.addDynamicComponent();
      this.wasChildInitialized = true;
      this.compRef.location.nativeElement.querySelector(':first-child').classList.add('entity-'+this.entity.name)
      var style = this.store.snapshot().app?.sider?.user?.space?.style;
      if (style){
        applyApplicationStyle(style);
      }
    }
  }

  ngOnDestroy(): void {
    if(this.compRef) {
      this.compRef.destroy();
      this.compRef = null;
    }
  }

  onCardClick(evt){
    if (evt.ctrlKey) {
      if (!this.openInModal) return;
      const recordRouterLink = '/record/' + this.record.id;
      window.open(recordRouterLink, '_blank');
    } else {
      evt.preventDefault();
      this.cardClick.emit({
        recordId: this.record.id,
        openModal: this.openInModal
      });
    }
  }

  private staticize(record, entity): object {
    if (typeof record !== 'object' || typeof entity !== 'object'){
      return {};
    }
    const fields = _.flatten(_.map(entity.blocks, 'fields'));
    const forbidden = /{{FORBIDDEN}}$/i;
    let accValue = {}, entityFieldsNameAccess = {}, entityFieldsIdAccess = {}, recordFieldsNameAccess = {};
    for (let field in fields) {
      let fieldName = fields[field]['name'];
      let fieldId = fields[field]['id'];
      entityFieldsNameAccess[fieldName] = fields[field];
      entityFieldsIdAccess[fieldId] = fields[field];
      if (fields[field].hasOwnProperty('id') && record.value?.hasOwnProperty(fields[field]['id'])) {
        let denominateField = {[fieldName]: record.value[fieldId] };
        if (typeof record.value[fieldId] === 'string' && forbidden.test(record.value[fieldId])){
          denominateField[fieldName] = undefined;
        }
        if (fieldTypeRecord.includes(fields[field].type) && !!fields[field].isExpanded && denominateField[fieldName]){
        const mirrorEntity = _.find(entity.mirrorEntities, e => e?.id === fields[field].mirrorFields[0].entity);
          if (fields[field].isMany && Array.isArray(denominateField[fieldName])) {
            const staticizedValues = [];
            for (const denominateFieldValue of denominateField[fieldName]) {
              const result: any = this.staticize(denominateFieldValue, mirrorEntity ? mirrorEntity : entity);
              staticizedValues.push(result.record);
            }
            denominateField[fieldName] = staticizedValues;
          } else if(!fields[field].isMany) {
            const result: any = this.staticize(denominateField[fieldName], mirrorEntity ? mirrorEntity : entity);
            denominateField[fieldName] = result.record;
          }
        }
        if (record.value[fieldId + '_']) {
          let denominateExtendedField = {
            [fieldName + '_filtered']: record.value[fieldId + '_']
          };
          accValue = {...accValue, ...denominateField, ...denominateExtendedField}
        } else {
          accValue = {...accValue, ...denominateField}
        }
      }
    }
    for (let field in record.fields){
      if (entityFieldsIdAccess[record.fields[field].id]){
        recordFieldsNameAccess[entityFieldsIdAccess[record.fields[field].id].name] = record.fields[field];
      }
    }
    let clonedRecord = {}, clonedEntity = {};
    let ignoreAttributes = ['actions', 'activities', 'fields'];
    for (const recordKey in record) {
      if (record.hasOwnProperty(recordKey) && !ignoreAttributes.includes(recordKey)) {
        if (typeof record[recordKey] === "object") {
          const recordVal = {[recordKey]: record[recordKey]}
          clonedRecord = {...clonedRecord, ...recordVal};
        } else {
          clonedRecord[recordKey] = record[recordKey];
        }
      }
    }
    for (const entityKey in entity) {
      if (entity.hasOwnProperty(entityKey)) {
        if (typeof entity[entityKey] === "object") {
          const entityVal = {[entityKey]: entity[entityKey]}
          clonedEntity = {...clonedEntity, ...entityVal};
        } else {
          clonedEntity[entityKey] = entity[entityKey];
        }
      }
    }
    clonedRecord['fields'] = recordFieldsNameAccess;
    clonedEntity['fields'] = entityFieldsNameAccess;
    let result = {
        ...clonedRecord,
        ...accValue,
      }
 const proxyFields = ['comments', 'attachments', 'activities',
  ..._.chain(entity.blocks).map('fields').flatten().filter(f => ['InverseOneToMany', 'ManyToMany', 'InverseManyToMany'].includes(f.type) && !f.isExpanded)
    .thru(fields => [
      ...fields.map(f => f.name),
      ...fields.map(f => f.name + '_filtered')
    ]).value()
];

    proxyFields.forEach((field) => {
      const originalCount = Array.isArray(result[field])
        ? result[field].length
        : result[field];

      Object.defineProperty(result, field, {
        get: () =>
          new Proxy({}, {
            get(target, prop) {
              if (prop === 'length') return originalCount;
              return target[prop];
            }
          }),
        configurable: true,
        enumerable: true,
      });
    });
    return {
      record: result ,
      entity: clonedEntity
    };
  }

  private addDynamicComponent () {
    if (this.compRef) {
      this.compRef.destroy();
    }
    const factory = this.factoryProvider.createComponentFactory(this.entity.template, this.entity.id);
    this.compRef = this.vcRef.createComponent(factory, 0, this.injector);
    if (this.compRef) {
      this.bindContext();
    } else {
      throw Error("An error occurred when creating dynamic card.")
    }
  }

  private bindContext() {
    this.compRef.changeDetectorRef.detach();
    for (let prop in this.childContext) {
      if (this.childContext.hasOwnProperty(prop)) {
        this.compRef.instance[prop] = this.childContext[prop];
      }
    }
    this.compRef.changeDetectorRef.detectChanges();
    this.compRef.changeDetectorRef.reattach();
  }
}
