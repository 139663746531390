import {Component, OnInit, ViewChild, Inject, HostListener, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { AdministrationPage } from 'src/app/state/app.actions';
import { AppState } from 'src/app/state/app.state';
import { NgbModal, NgbNav, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { LocalService } from 'src/app/services/local.service';
import { UserService } from 'src/app/services/user.service';
import { translate, TranslocoDirective } from "@jsverse/transloco"
import {Cache} from "../../classes/cache";
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpecialTitleCasePipe } from '../../pipes/special-title.pipe';
import { NgArrayPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { ConfirmationComponent } from '../../components/confirmation/confirmation.component';
import { LoaderComponent } from '../../components/loader/loader.component';
import { ChoiceComponent } from '../../components/choice/choice.component';
import { CreateComponent } from '../../components/create/create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EmptyComponent } from '../../components/empty/empty.component';
import { AttachmentsComponent } from '../../components/attachments/attachments.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgTemplateOutlet, NgSwitchDefault, NgClass, AsyncPipe, UpperCasePipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { DefaultComponent } from '../../components/default/default.component';


@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DragulaService],
    standalone: true,
    imports: [TranslocoDirective, DefaultComponent, NgIf, NgbNav, NgFor, NgbNavItem, NgbNavItemRole, NgbNavLink, NgbNavLinkBase, NgbNavContent, RouterLinkActive, RouterLink, NgSwitch, NgSwitchCase, NgTemplateOutlet, NgSwitchDefault, AttachmentsComponent, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbNavOutlet, EmptyComponent, InfiniteScrollModule, DragulaModule, FormsModule, AutofocusDirective, NgSelectModule, CreateComponent, NgClass, ChoiceComponent, LoaderComponent, ConfirmationComponent, AsyncPipe, UpperCasePipe, LowerCasePipe, TitleCasePipe, NgArrayPipesModule, NgStringPipesModule, SpecialTitleCasePipe]
})
export class AdministrationComponent implements OnInit, OnDestroy {

  @Select(AppState.administrationPage)
  public administrationPage$;
  @Select(AppState.sider) sider$;

  public mode;
  public object;
  public model;
  public modelName;
  public entityId;
  public modal;
  private oldRoles = [];
  public cursor = -1;
  public loading;
  public confirmationModal;
  public confirmationArgs: object;
  public deleteModel;
  public choiceId;
  public scrollDisabled;
  public choicesLoading;
  public choicesStr;
  public modalScrollDisabled = true;
  public modalAllElementsLoaded = false;
  public modalCursor = -1;
  public entitiesModels
  public permissionsModels
  public added
  public isDragging: boolean = false;
  public addedSpaces;
  public oldSpaces = [];
  public allElementsLoaded = false;

  titleNameTranslation = 'title';
  spaceLanguages = [];
  activeLang = "fr";

  destroyed$: Subject<any> = new Subject();

  @ViewChild("t") public tabset;
  @ViewChild('moreButton') moreButton: ElementRef;
  @ViewChild("sectionsContainer", { static: false }) sectionsContainer: ElementRef;
  @ViewChildren("sections", { read: ElementRef }) sections: QueryList<ElementRef>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store,
              public modalService: NgbModal,
              public toastrService: ToastrService,
              private dragulaService: DragulaService,
              private localService: LocalService,
              public userService: UserService,
              private changeDetector: ChangeDetectorRef,
              @Inject('Cache') private cache: Cache) {

    dragulaService.destroy('SORTCHOICES');
    dragulaService.createGroup("SORTCHOICES", {
      moves: (el, container, handle) => {
        return handle.className.includes('choices-handle');
      },

    });

    dragulaService.dropModel('SORT').subscribe(obj => {
      if(obj.targetModel.length > 1){
        let targetModel = obj.targetModel.map((o, index) => ({ id: o.id, position: index, entity: this.entityId }));

        this.store.dispatch(new AdministrationPage.UpdateObject(targetModel));
      }
    });

    dragulaService.drag().subscribe(() => {
      this.isDragging = true;
      document.addEventListener('mousemove', this.onMouseMove);
    });

    dragulaService.dragend().subscribe(() => {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onMouseMove);
    });

    dragulaService.dropModel('SORTCHOICES').subscribe(obj => {
      if (obj.targetModel.length > 1) {
          const droppedChoice = obj.item.id;
          const moveDiff = obj.targetIndex - obj.sourceIndex;

          // Get the choiceset based on the first item in targetModel
          const choiceset = obj.targetModel[0].choiceset;

          // Retrieve the field and choices from the store
          const field = this.store.selectSnapshot(state => state.app.administrationPage.objects.find(o => o.choiceset === choiceset));
          const choices = _.cloneDeep(field.choices);

          // Assign initial positions
          choices.forEach((choice, index) => {
            choice.position = index;
          });

          // Update positions
          const updatePosition = (choice) => {
            if (choice.id === droppedChoice) {
              choice.position += moveDiff;
            } else if (moveDiff > 0 && choice.position > obj.sourceIndex && choice.position <= obj.targetIndex) {
              choice.position -= 1;
            } else if (moveDiff < 0 && choice.position >= obj.targetIndex && choice.position < obj.sourceIndex) {
              choice.position += 1;
            }
          };

          choices.forEach(updatePosition);

          // Sort the choices array based on the updated positions
          choices.sort((a, b) => a.position - b.position);


        this.store.dispatch(new AdministrationPage.UpdateObject({ id: field.choiceset, choices_: choices }, this.modalCursor))
          .subscribe(res => {
            this.cache.clearContains('choices', [[{}, { cached: { queryParam: 'choiceset' } }]], field.choiceset);
            this.object = this.store.snapshot().app.administrationPage.objects.find(obj => obj.id === this.object.id);
          }, () => {});
      }

    });

  }

  ngOnInit() {
    this.store.select(state => state.app.sider?.user?.space?.availableLanguages).subscribe(
      availableLanguages => this.spaceLanguages = availableLanguages
    )
    this.setTitleNameTranslation(this.activeLang);

    this.route.paramMap.subscribe(params => {
      let initialQp = this.route.snapshot.queryParams;
      let alwaysTriggerSearch = false;
      this.cursor = -1;
      let model = params.get('model');
      this.modelName = model;
      let entityId = parseInt(params.get('entityId'));
      this.entityId = entityId;
      this.store.dispatch(new AdministrationPage.Init(entityId, model, this.cursor, this.route.snapshot.queryParams.search && params.get("model") === "user" ? this.route.snapshot.queryParams.search : "")).subscribe(res => {
        setTimeout(() => {
          this.scrollDisabled = this.allElementsLoaded = res.app.administrationPage.currentModel?.count === res.app.administrationPage.objects.length;
        }, 0);
        if(this.tabset){
          this.tabset.select(entityId);
          this.calculateHiddenSections();
        }
        if (params.get("model") === "user"){
          this.route.queryParams.subscribe(qp => {
            if (qp.search !== initialQp.search || alwaysTriggerSearch){
              window.scrollTo(0, 0)
              this.cursor = -1
              alwaysTriggerSearch = true;
              this.store.dispatch(new AdministrationPage.SearchObjects(entityId, model, this.cursor, qp.search ? qp.search : "", ['spaceRoles'])).subscribe(res => {
                setTimeout(() => {
                  this.scrollDisabled = this.allElementsLoaded = res.app.administrationPage.currentModel?.count === res.app.administrationPage.objects.length;

                }, 0);
              });
            }
          })
        }
      }, () => {})
    })
    this.localService.siderVisibility$.pipe(delay(300), distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(() => {
        this.calculateHiddenSections();
    })
    this.sider$.pipe(takeUntil(this.destroyed$)).subscribe(state =>{
      if (state.isReady) {
        this.calculateHiddenSections();
      }
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined);
    this.destroyed$.complete();
  }

  public track(index, object) {
    return object.id;
  }

  getConfirmationDeleteBody() {
    return translate("Vous êtes sur le point de supprimer définitivement ce record. Etes-vous sûr que vous en êtes le créateur ou autorisé à effectuer cette suppression ??",{title:this.deleteModel.title, cet:this.deleteModel.isMasculine ? 'cet' : 'cette'});
  }

  show(e){
    let id = parseInt(e.nextId);
    let firstField;
    // if permissions tab is active
    if(id === 0){
      firstField = 'user';
    }
    else if(id === -1){
      firstField = 'datalake';
    }
    else{
      firstField = 'field';
    }
    this.router.navigate(['administration/'+id+'/'+firstField]);
  }

  openModal(model, mode, object, modalRef) {
    this.mode = mode;
    this.model = model
    this.object = _.cloneDeep(object);

    if(this.model.modelName === 'user') {
      if(this.mode === 'import') {
        this.object.users= "";
        this.object.currentSpaces = [];
        this.object.spaces = [];
        this.object.currentRoles = [];
        this.object.roles = [];
      }
      else if(this.mode === 'create') {
        this.object.currentRoles = [];
        this.object.roles = [];
        this.object.currentSpaces = []
        this.object.spaces = []
      }
      else if(this.mode === 'update') {
        this.object.currentRoles= [];
        for(let role of model.parent.values) {
          for(let userRoleId of this.object.roles) {
            if(role.id === userRoleId) {
              this.object.currentRoles.push(userRoleId);
            }
          }
        }
        this.object.currentSpaces= [];
        for(let space of model.client.values) {
          for(let userSpaceId of this.object.spaces) {
            if(space.id === userSpaceId) {
              this.object.currentSpaces.push(userSpaceId);
            }
          }
        }
      }
      this.oldRoles = this.object.currentRoles;
      this.oldSpaces = this.object.currentSpaces;
    }

    if(this.model.modelName === 'tag' && this.mode === 'create') {
      let objects = this.store.snapshot().app.administrationPage.objects;
      let lastPosition = objects.length ? objects[objects.length-1].position+1 : 0;
      this.object.position = lastPosition;
    }

    this.modal = this.modalService.open(modalRef, {size: this.model.modalSize});
  }

  openChoicesModal(model, object, modalRef) {
    this.model = model
    this.modalCursor = -1;
    this.modalAllElementsLoaded = false;
    this.object = _.cloneDeep(object);
    this.choicesLoading = true;
    this.modal = this.modalService.open(modalRef, {size: this.model.modalSize}).shown.subscribe(res => {
      this.store.dispatch(new AdministrationPage.RetrieveFieldChoices(this.object, -1)).subscribe(res => {
        this.choicesLoading = false;
        this.modalScrollDisabled = false;
        this.object = this.store.snapshot().app.administrationPage.objects.find(obj => obj.id === this.object.id);
        if (res.app.administrationPage.objects.find(obj => obj.id === this.object.id).choicesLength === res.app.administrationPage.objects.find(obj => obj.id === this.object.id).choices.length){
          this.modalAllElementsLoaded = true
        }
      })
    })
  }

  openConfirmation(ref: any, confirmationArgs: object) {
    if (!this.deleteModel){
      this.entitiesModels= this.store.snapshot().app.administrationPage.entitiesModels;
      this.permissionsModels=this.store.snapshot().app.administrationPage.permissionsModels;
      this.deleteModel = [...this.entitiesModels,...this.permissionsModels].find(model => model.modelName === this.modelName);
    }
    this.confirmationArgs = confirmationArgs
    this.confirmationModal = this.modalService.open(ref);
  }

  processSubmit(res){
    if (res.action === 'delete') {
      this.loading = 'delete';
      this.store.dispatch(new AdministrationPage.DestroyObject(res.id)).subscribe(_ => {
        this.loading = null;
        this.modal.close();
        this.toastrService.success(translate('Opération effectuée'));
      }, () => {
        this.loading = null;
      })
    } else if (res.action === 'update') {
      // delete user => update isActive=false
      if (res.kwargs) {
        this.loading = 'delete';
        for (let arg in res.kwargs) {
          res.object[arg] = res.kwargs[arg];
        }
      } else {
        this.loading = 'update';
      }
      this.store.dispatch(new AdministrationPage.UpdateObject(res.object)).subscribe(_ => {
        this.loading = null;
        this.modal.close();
        this.toastrService.success(translate('Opération effectuée'));
      }, () => {
        this.loading = null
      });
    } else if (res.action === 'create') {
      this.loading = 'create';
      this.store.dispatch(new AdministrationPage.CreateObject(res.object)).subscribe(_ => {
        this.loading = null;
        this.modal.close();
        this.toastrService.success(translate('Opération effectuée'));
      }, () => {
        this.loading = null
      });
    }
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }
  }
  add(){
  this.object.roles = this.object.roles.filter( element => {
    return this.oldRoles.indexOf( element ) < 0;
  } );
  if (this.added){
    this.object.roles.push(...this.added);
    this.object.currentRoles = this.added;
    this.oldRoles = this.object.currentRoles;
  }
 }
 addSpace(){
   this.object.spaces = this.object.spaces.filter( element => {
     return this.oldSpaces.indexOf( element ) < 0;
   } );
   if (this.addedSpaces){
     this.object.spaces.push(...this.addedSpaces);
     this.object.currentSpaces = this.addedSpaces;
     this.oldSpaces = this.object.currentSpaces;
   }

 }
  // for user
  updateUserRole(roles) {
    roles = roles.map(role => role.id);
    this.added = roles
  }
  updateUserSpace(spaces) {
    spaces = spaces.map(space => space.id);
    this.addedSpaces = spaces
  }

  getUserToUpdate(user) {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      currentSpaces: user.currentSpaces,
      spaces:user.spaces,
      currentRoles: user.currentRoles,
      roles: user.roles
    }
  }

  onScroll() {
    this.cursor = this.store.snapshot().app.administrationPage.objects[this.store.snapshot().app.administrationPage.objects.length - 1].id;
    let qp = this.route.snapshot.queryParams.search;
    this.scrollDisabled = true;
    this.store.dispatch(new AdministrationPage.RetrieveObjects(this.entityId, this.modelName, this.cursor, this.modelName === 'user' && qp ? qp : "")).subscribe(res => {
      setTimeout(() => {
        this.scrollDisabled = this.allElementsLoaded = res.app.administrationPage.currentModel?.count === res.app.administrationPage.objects.length;
        this.changeDetector.detectChanges();
      }, 0);
    });
  }

  onSubmit(res) {
    this.processSubmit(res);
  }

  onConfirmationSubmit() {
    if (this.confirmationArgs) {
      this.processSubmit(this.confirmationArgs);
    }
  }

  onConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  onChoicesetTabLangChange(lang: string){
    this.activeLang = lang;
    this.setTitleNameTranslation(this.activeLang);
  }

  setTitleNameTranslation(lang: string){
    this.titleNameTranslation = 'title' + lang.charAt(0).toUpperCase() + lang.slice(1);
  }

  onChoiceTitleChange(choice, title: string) {
    if (choice[this.titleNameTranslation] === title) return;
    let value = {
      id: choice.id,
      [this.titleNameTranslation]: title,
    };

    this.store.dispatch(new AdministrationPage.UpdateChoice(value)).subscribe(res => {
      this.object = this.store.snapshot().app.administrationPage.objects.find(obj => obj.id === this.object.id);
      this.toastrService.success(translate('Titre de choix modifié'));
    }, () => {});
  }

  onChoiceCreateSubmit(title: string) {
    if(!title.trim()){
      this.toastrService.error(translate('titre ne peut pas être vide'));
      return;
    }
    let position = this.object.choices.length ? this.object.choices[this.object.choices.length - 1].position + 1 : 0;
    let choice = {
      titleFr: title,
      choiceset: this.object.choiceset,
      position
    };

    this.store.dispatch(new AdministrationPage.CreateChoice(choice)).subscribe(res => {
      this.object = this.store.snapshot().app.administrationPage.objects.find(obj => obj.id === this.object.id);
      this.toastrService.success(translate('Choix ajouté'))
    }, () => {});
  }

  onChoiceDelete(id) {
    this.choiceId = id;
    this.store.dispatch(new AdministrationPage.DestroyChoice(id)).subscribe(res => {
      this.object = this.store.snapshot().app.administrationPage.objects.find(obj => obj.id === this.object.id);
      this.choiceId = null;
      this.toastrService.success(translate('Choix supprimé'))
    }, () => {this.choiceId = null;});
  }

  openImportCheckpointsModal(modlaRef) {
    this.modalService.open(modlaRef).result.then((result) => {
      if (!result) return;
      this.choicesStr = this.choicesStr.trim();
      let choices = result.trim().split('\n').filter((choice) => choice.trim() !== '');
      if (!choices.length) return;
      let lastPosition = this.object.choices.length ? this.object.choices[this.object.choices.length - 1].position + 1 : 0;
      let newchoices = [];

      for (let choice of choices) {
        newchoices.push({
          titleFr: choice,
          choiceset: this.object.choiceset,
          position: lastPosition++
        })
      }

      this.store.dispatch(new AdministrationPage.CreateChoices(this.object, newchoices)).subscribe(res => {
        this.choicesStr = '';
        this.toastrService.success(translate('Choix ajouté',{s:'s'}));
        this.object = this.store.snapshot().app.administrationPage.objects.find(obj => obj.id === this.object.id);
      }, () => {});

    }, (reason) => {

    });
  }

  onExcelExport() {
    window.open(this.userService.getExcelUrl());
  }

  onModalScroll(){
    this.modal == this.object.choices[this.object.choices.length - 1].id;
    this.modalScrollDisabled = true;
    this.store.dispatch(new AdministrationPage.RetrieveFieldChoices(this.object, this.modalCursor)).subscribe(res => {
      this.modalScrollDisabled = false;
      this.object = this.store.snapshot().app.administrationPage.objects.find(obj => obj.id === this.object.id);
      if (this.object.choicesLength && this.object.choicesLength === this.object.choices.length){
        setTimeout(() => {
          this.modalAllElementsLoaded = true
          this.modalScrollDisabled = true
        })
      }
    })
  }

  onDataLakeFileCreate(event){
    this.store.dispatch(new AdministrationPage.CreateDatalakeFile(event));
  }

  onLoadMore(evt) {
    let qp = this.route.snapshot.queryParams.search;
    this.store.dispatch(new AdministrationPage.RetrieveObjects(this.entityId, this.modelName, evt.page,this.modelName === 'user' && qp ? qp : ""))
  }

  onMouseMove = (event: MouseEvent) => {
    if (this.isDragging) {
      const modalBody = document.getElementsByClassName('modal-body');
      const scrollArea = document.getElementsByClassName('scroll-area');
      const draggedElement = document.getElementsByClassName('gu-transit');

      if (draggedElement[0]) {
        const position = draggedElement[0].getBoundingClientRect();
        const scrollTop = position.top;
        const isScrolledToBottom = modalBody[0]?.scrollHeight < scrollTop;

        if (isScrolledToBottom) {
          scrollArea[0].scrollTo({
            top: scrollArea[0]?.scrollHeight,
            behavior: 'smooth'
          });
        }
      }
    }
  };

  sectionIsHidden(section) {
    return _.find(this.store.snapshot().app.administrationPage.hiddenSections, u => u.entity.id === section.entity.id) != undefined
  }

  calculateHiddenSections() {
    if (this.sectionsContainer && this.sections.length) {
      const sectionsContainer = this.sectionsContainer.nativeElement;
      sectionsContainer.classList.add("overflow-hidden");
      const sections = _.cloneDeep(this.sections);
      let hiddenSections = [];
      let moreButtonWidth = 0;
      if (this.moreButton) {
        this.moreButton.nativeElement.hidden = false;
        moreButtonWidth = this.moreButton.nativeElement.clientWidth;
      }
      sections.forEach((section, index) => { section.nativeElement.hidden = false });
      const activeSection = sections.filter((e, i)=> e.nativeElement.firstChild.classList.contains('active'))[0];
      let visibleWidth =  moreButtonWidth + (activeSection?.nativeElement.clientWidth ? activeSection?.nativeElement.clientWidth : 0);
      sections.forEach((section, index) => {
        if (visibleWidth + section.nativeElement.clientWidth >= (sectionsContainer.clientWidth - 35)) {
          if (!section.nativeElement.firstChild.classList.contains('active')) {
            visibleWidth += section.nativeElement.clientWidth;
            section.nativeElement.hidden = true;
            hiddenSections.push(_.find(this.store.snapshot().app.administrationPage.sections, u => u?.entity?.id === +section.nativeElement.id.split(" ")[1]));
          }
        } else {
          if (!section.nativeElement.firstChild.classList.contains('active')) visibleWidth += section.nativeElement.clientWidth;
        }
      });
      if (hiddenSections.length == 0) {
        this.moreButton.nativeElement.hidden = true;
      }
      this.store.dispatch(new AdministrationPage.UpdateHiddenSections(hiddenSections));
      sectionsContainer.classList.remove("overflow-hidden");
      this.sections = sections;
    }
  }
}
