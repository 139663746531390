import {ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as _ from "lodash";
import {ToastrService} from "ngx-toastr";
import { translate, TranslocoDirective } from "@jsverse/transloco";
import { ReplaceStrPipe } from '../../pipes/replace-str.pipe';
import { SpecialTitleCasePipe } from '../../pipes/special-title.pipe';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { LoaderComponent } from '../loader/loader.component';
import { UploadComponent } from '../upload/upload.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { EditorComponent } from '../editor/editor.component';
import { IconComponent } from '../icon/icon.component';
import { SignPadComponent } from '../signpad/signpad.component'; 
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'formfield',
    templateUrl: './form-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, IconComponent, TranslocoDirective, NgSwitch, NgSwitchCase, EditorComponent, AutofocusDirective, DatepickerComponent, UploadComponent, LoaderComponent, NgSwitchDefault, AutocompleteComponent, SpecialTitleCasePipe, ReplaceStrPipe, SignPadComponent]
})
export class FormFieldComponent implements OnInit{

  @Input() formField;
  @Input() entityId;
  @Input() record = null;

  get initialValue() {
    return this._initialValue;
  }

  @Input() set initialValue(value) {
    if(value) {
      this._initialValue = value;
    }
  }

  @Input() focused = false;

  _initialValue = null;

  value;
  rawValue;
  progress = false;
  constructor(public changeDetector: ChangeDetectorRef, public toastr: ToastrService) {
  }

  ngOnInit(): void {
    if (this.initialValue){
      this.rawValue = this.value = this.initialValue;
    } else {
      this.rawValue = this.value = (this.formField.type === 'Users' || this.formField.type === 'MultiSelect') ? [] : null;
    }
  }

  onAutocompleteSelectItem($event: any) {
    if (this.formField.type === 'Users' || this.formField.type === 'MultiSelect') {
      this.rawValue = _.orderBy($event, ['id'], ['desc']);
      this.value = this.rawValue.map(v => v.id);
    } else {
      this.rawValue = $event;
      this.value = $event?.id;
    }
  }

  onValueChange($event: any) {    
    if (this.formField.type == 'Image' || this.formField.type == 'Ressource' || this.formField.type === 'E-SignPad') {
      const file = new FileReader();
      if (this.formField.type === 'E-SignPad') {    
        if (!$event) {
          this.value = null;
          this.rawValue = null;
          this.changeDetector.detectChanges();
          return;
        }    
        this.value = {
          content: $event.split(',')[1], 
          name: 'signature.png',
        };
        this.rawValue = this.value;
        this.changeDetector.detectChanges();
        return;
      }
      file.readAsDataURL($event)
      file.onload = (f: any) => {
        if (this.formField.type == 'Ressource') {
          this.value = {'content': f.target.result.split(',')[1], 'name': $event.name}
        } else {
          if (!$event.type.includes('image')){
            this.toastr.error(translate("Le fichier sélectionné doit être une image"))
            return
          }
          this.value = f.target.result;
        }
        this.rawValue= this.value;
      }
      file.onprogress = () =>{
        this.progress = true;
      }
      file.onloadend = () => {
        this.progress = false;
        this.changeDetector.detectChanges();
      }
    } else {
      this.value = $event;
      this.rawValue = $event;
    }
  }
}
