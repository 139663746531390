import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, input, output, signal, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownItem, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TrackDirective } from 'src/app/directives/track.directive';
import { blocksToFlattenFields } from 'src/app/app.utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'display-mode-menu',
  standalone: true,
  imports: [NgClass, NgbDropdown, NgbDropdownMenu, NgbDropdownItem, NgTemplateOutlet,NgbDropdownToggle, TrackDirective, TranslocoDirective],
  templateUrl: './display-mode-menu.component.html',
  styleUrl: './display-mode-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayModeMenuComponent implements OnInit{

  queryParamsConfigMapping = {
    'cards': {  
      'dashboardConfig': null,
      'calendarConfig': null,
      'timelineConfig': null,
      'timeframe': null,
      'mode': null
    },

    'grid': {
      'dashboardConfig': null,
      'calendarConfig': null,
      'timelineConfig': null,
      'timeframe': null
    },

    'dashboard': {
      'calendarConfig': null,
      'timelineConfig': null,
      'timeframe': null,
      'ordering': null,
      'groupby': null
    },

    'calendar': {
      'dashboardConfig': null,
      'timelineConfig': null,
      'ordering': null
    },

    'timeline': {
      'dashboardConfig': null,
      'calendarConfig': null,
      'timelineConfig': JSON.stringify({scope: 'month'}),
      'timeframe': null
    }
  }

  entity = input.required<any>();
  isMobile = input(false);
  modeIcon = input.required<any>();
  modeTitle = input.required<any>();

  modeSwitch = output<Record<string, any>>();

  activeMode = signal('cards');
  modeItems = signal([]);
  nonTimingModeItems = computed( () => this.modeItems().filter(item => item.mode !== 'timeline' && item.mode !== 'calendar') );
  timingModeItems = computed( () => this.modeItems().filter(item => item.mode === 'timeline' || item.mode === 'calendar') );

  private route = inject(ActivatedRoute);

  constructor(){
  }

  ngOnInit(): void {
    this.activeMode.set(this.route.snapshot.queryParams.mode ?? 'cards');
    this.modeItems.set(this.getAvailableModes().map(mode => (
      {
        mode,
        title: this.modeTitle()[mode], 
        icon: this.modeIcon()[mode],
      }
    )));
  }

  switchMode(mode: string){
    const datefields = blocksToFlattenFields(this.entity().blocks).filter(f => f.hasFilter && f.type == "Date")
    let queryParams = {'page': null, 'mode': mode , ...this.queryParamsConfigMapping[mode]}
    
    if (mode === 'calendar' || mode === 'timeline'){
      queryParams = this.removeCalendarOrTimelineExcludedFilters(datefields, mode, queryParams );
    }

    this.modeSwitch.emit(queryParams);
    this.activeMode.set(mode);
  }

  removeCalendarOrTimelineExcludedFilters(datefields: any[], mode: string, queryParams: Record<string, any>): Record<string, any> {
    datefields.forEach((element) => {
      const isExcludedOnCalendar = mode === 'calendar' && element.id === this.entity().endingDateField?.id;
      const isExcludedOnTimeLine = mode === 'timeline' && ( this.entity().startingDateField?.id === element.id 
                                                          || this.entity().endingDateField?.id === element.id );
      
      if (isExcludedOnCalendar || isExcludedOnTimeLine) {
        queryParams[element.name] = null;
      }; 
    });
    return queryParams;
  }

  getAvailableModes(){
    const modes = ['cards']

    if(this.entity().hasTable) modes.push('grid');

    if(this.entity().hasPivotTable) modes.push('dashboard');

    if(this.entity().endingDateField) modes.push('calendar');
    
    if(this.entity().startingDateField || this.entity().endingDateField) modes.push('timeline');

    return modes;
  }
}