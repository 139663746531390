import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ElementRef} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {map, mergeMap} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import {UserService} from "../../services/user.service";
import { LocalService } from 'src/app/services/local.service';
import * as _ from 'lodash';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import { MomentModule } from 'ngx-moment';
import { CheckDirective } from '../../directives/check.directive';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { RthighlightDirective } from '../../directives/rthighlight.directive';
import { MentionModule } from 'angular-mentions';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { AutosizeModule } from 'ngx-autosize';
import { NgIf, AsyncPipe, NgFor, NgTemplateOutlet, NgClass } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { GroupReactionsPipe } from '../../pipes/group-reactions.pipe';
import { NgClickOutsideDelayOutsideDirective, NgClickOutsideDirective } from 'ng-click-outside2';
import * as moment from 'moment';

@Component({
    selector: 'comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, NgFor, NgTemplateOutlet, NgClass, FormsModule, AutosizeModule, AutofocusDirective, ReactiveFormsModule, MentionModule, RthighlightDirective, NgbDropdown, CheckDirective, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbPopover, AsyncPipe, MomentModule, PickerComponent, GroupReactionsPipe, NgClickOutsideDirective, NgClickOutsideDelayOutsideDirective]
})
export class CommentComponent implements OnInit, OnChanges {
  @Input() mode;
  @Input() comment;
  @Input() user = null;
  @Input() recordId;
  @Input() checkpointId;
  @Output() commentSubmit = new EventEmitter();
  @Output() commentDelete = new EventEmitter();
  @Output() reactionUpdate = new EventEmitter();
  @ViewChild('emojiInput') emojiInput!: ElementRef<HTMLInputElement>;
  mentionConfig = {disableSearch: true, allowSpace: true, labelKey: 'fullName', mentionSelect: this.onMentionSelection,
                  parentRef: this};
  valueControl;
  users;
  mentionsMapping = {};
  commentDisplay;
  moment = moment;
  showEmojiPicker = false;
  darkMode;
  constructor(public userService: UserService, public localService: LocalService) { }

  ngOnInit() {
    this.localService.theme$.subscribe((val) => {
      this.darkMode = val == 'dark';
    })
  }

  ngOnChanges(){
    if (typeof(this.comment) == 'object') {
      let comment = _.cloneDeep(this.comment);
      let commentText = comment.text;
      let commentDisplay = DOMPurify.sanitize(marked(comment.text, {breaks: true, gfm: true}));
      this.comment.mentions.forEach((mention) => {
        this.mentionsMapping[mention.id] = mention.fullName;
        const globalRegex = new RegExp('@' + mention.id, 'g');
        commentText = commentText.replace(globalRegex, '@' + mention.fullName);
        //TDO mention template redesigned
        commentDisplay = commentDisplay.replace(globalRegex,
          `<span class="badge rounded-pill badge-light-info fw-semibold out fs-6">
              <img width="25px" class="rounded me-2" src="${mention.picture}">
              ${mention.fullName}
            </span>`
        );
      });
      comment.text = commentText;
      this.comment = comment;
      this.commentDisplay = commentDisplay;
    }
    this.valueControl = new UntypedFormControl(typeof(this.comment) == 'object' ? this.comment.text : '');
  }

  onSubmitHandler() {
    if (!this.valueControl.value) return;
    this.mode = this.mode == "update" ? 'read' : 'createCollapsed';
    let value = this.valueControl.value;
    Object.keys(this.mentionsMapping).forEach((key) => {
      const globalRegex = new RegExp('@' + this.mentionsMapping[key], 'g');
      value = value.replace(globalRegex, '@' + key);
    });
    this.commentSubmit.emit(value);
    this.valueControl.setValue('');
  }

  onCancel() {
    this.mode === 'update' ? this.mode = 'read' : this.mode = 'createCollapsed';
    this.valueControl = new UntypedFormControl(this.comment ? this.comment.text : '');
  }

  onMentionSelection(selected) {
    this['parentRef'].mentionsMapping[selected.id] = selected.fullName;
    return '@' + selected.fullName;
  }

  onDeleteHandler() {
    this.commentDelete.emit(this.comment);
  }

  searchUsers(term) {
  this.users = this.userService.retrieveObjects({cursor: -1, search: term, record: this.recordId}).pipe(
    map((response: any) => response.data || [])
  );
}



  togglePicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    if (this.emojiInput) {
      const textarea = this.emojiInput.nativeElement;
      textarea.focus();
    }
  }

  hideEmojiPicker(evt) {
    if (this.showEmojiPicker) {
      this.showEmojiPicker = false;
    }
  }

  onEmojiSelect(event: any) {
    const emoji = event.emoji?.native || event;
    if (this.emojiInput) {
      const textarea = this.emojiInput.nativeElement;
      const start = textarea.selectionStart || 0;
      const end = textarea.selectionEnd || 0;

      this.valueControl.setValue(
        this.valueControl.value.substring(0, start) + emoji + this.valueControl.value.substring(end)
      );
      // Set the cursor position after the inserted emoji
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + emoji.length;
        textarea.focus();
      }, 0);
    } else {
      this.reactionUpdate.emit(emoji);
      this.showEmojiPicker = false;
    }
  }

  isCurrentUserReaction(users) {
    return users.some(user => user.id === this.localService.getUser());
  }
}
