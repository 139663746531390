import {State, Action, Store, Selector} from '@ngxs/store';
import {Sider, Header, RecordsPage, PreferencesPage} from './app.actions';
import {mergeMap, tap} from 'rxjs/operators';
import {forkJoin, of, map} from 'rxjs';
import {LocalService} from '../services/local.service';
import {PageService} from '../services/page.service';
import {UserService} from '../services/user.service';
import {Router} from '@angular/router';
import {mutateState} from '../app.utils';
import {NotificationService} from '../services/notification.service';
import {Inject, Injectable} from '@angular/core';
import {Cache} from '../classes/cache';
import {StateResetAll} from 'ngxs-reset-plugin';
import {WebSocketService} from '../services/websocket.service';
import {TranslocoService} from '@jsverse/transloco'
import {NgxIndexedDBService} from "ngx-indexed-db";
import * as _ from 'lodash';


@State({
  name: 'sider',
  defaults: {
    pages: [],
    spaces: [],
    primaryPages: [],
    nonPrimaryPages: [],
    user: null,
    unseenNotificationsCount: 0,
    isReady: false,
    visibleSpaces: [],
    flatSpaces: [],
  }
})

@Injectable()
export class SiderState {
  constructor(private localService: LocalService,
              private userService: UserService,
              private router: Router,
              private notificationService: NotificationService,
              @Inject('Cache') private cache: Cache,
              private store: Store,
              private webSocketService: WebSocketService,
              private dbService: NgxIndexedDBService) {
  }


  @Selector()
  static getUser(state) {
    return state.user;
  }

  @Action(Sider.Init, {cancelUncompleted: true})
  init(ctx, {params, queryParams}) {
    return this.userService.retrieveCurrentUser(this.localService.getUser()).pipe(
      mergeMap((user: any) => {
        if (user.space.id !== Number(this.localService.getSpace())) {
          this.localService.setSpace(user.space.id);
        }
        if (!user.space.availableLanguages.includes(this.localService.getLanguage())) {
          this.store.dispatch(new PreferencesPage.UpdateUserLanguage(user.space.availableLanguages[0]));
        }
        return of(user)
      }),
      mergeMap((user: any) => {
        mutateState(ctx, draft => {
          draft.user = user;
          if (!_.isEqual(draft.spaces, user.spaces)) {
            draft.spaces = user.spaces;
          }
          if (!_.isEqual(draft.pages, user.extra?.pages)) {
            draft.pages = user.extra?.pages;
            draft.primaryPages = user.extra?.pages.filter(page => page.isPrimary);
            draft.nonPrimaryPages = user.extra?.pages.filter(page => !page.isPrimary);
          }
          if (!_.isEqual(draft.visibleSpaces, user.extra?.visibleSpaces)) {
            const flattenSpaces = (spaces) => _.flatMapDeep(spaces,
                space => [space, flattenSpaces(space.spaceChildren)]);
            draft.visibleSpaces = user.extra?.visibleSpaces;
            draft.flatSpaces = flattenSpaces(user.extra?.visibleSpaces);
          }
          draft.isReady = (params?.isReady != null) ? params.isReady : true;
          if(draft.isReady) {
            this.store.dispatch(new Header.GetUnseen());
          }else{
            draft.unseenNotificationsCount = user.extra.notificationCount;
          }

        });

        return of(user);
      }),
      mergeMap((user: any) => {
        if (this.router.isActive('/records', false)) {
          try {
            const cleanedUserRecord = _.omit(user.saves.records[params.id], ['cursor', 'v']);
            const cleanedQueryParams = _.omit(queryParams, ['cursor', 'v']);
            if (!_.isEqual(cleanedUserRecord, cleanedQueryParams)) {
              let saves = _.cloneDeep(user.saves);
              saves.records[params.id] = queryParams;
              return this.userService.updateObject({
                id: this.localService.getUser(),
                saves: saves
              }, ['space.ssoGateway']);
            } else {
              return of(user);
            }
          } catch (err) {
            return this.userService.updateObject({
              id: this.localService.getUser(),
              saves: {home: {}, records: {}, record: {}}
            }, ['space.ssoGateway']);
          }
        } else {
          return of(user);
        }
      }),
      tap((user: any) => {
        const updatedUser = _.cloneDeep(ctx.getState().user);
        updatedUser.saves = user.saves;
        mutateState(ctx, draft => {
          draft.user = updatedUser;
        });
      }));
  }



  @Action(Sider.SaveUserTableHiddenCols)
  saveUserTableHiddenCols(ctx, {entityId, columnIds}) {
    let saves = _.cloneDeep(ctx.getState().user.saves);
    saves.records[entityId] = {...saves.records[entityId], "hiddenColumns": columnIds}
    return this.userService.updateObject({
      id: this.localService.getUser(),
      saves: saves
    }, ['space.ssoGateway'])
      .pipe(
        tap((user: any) => {
          const updatedUser = _.cloneDeep(ctx.getState().user);
          updatedUser.saves = user.saves;
          mutateState(ctx, draft => {
            draft.user = updatedUser;
          });
        })
      );
  }


  @Action(Sider.SaveUserColumnsPositions)
  saveUserColumnsPositions(ctx, {entityId, columns}) {
    return this.userService.updateObject({
      id: this.localService.getUser(),
      saves: {
        ...ctx.getState().user.saves,
        records: {
          ...ctx.getState().user.saves.records,
          [entityId]: {
            ...ctx.getState().user.saves.records[entityId],
            columns
          }
        }
      }
    }).pipe(
      tap((user: any) => {
        mutateState(ctx, draft => {
          draft.user.saves = user.saves;
        });
      })
    );
  }


  @Action(Header.GetUnseen)
  getUnseen(ctx) {
    return this.notificationService.retrieveObjects({user: this.localService.getUser(), is_seen: false}).pipe(
      tap((notifications: any) => {
        mutateState(ctx, draft => {
          draft.unseenNotificationsCount = notifications.count
        });
      })
    );
  }


  @Action(Header.UpdateIsSeen)
  updateIsSeen(ctx, {isSeen}) {
    mutateState(ctx, draft => {
      if (isSeen) {
        draft.unseenNotificationsCount = draft.unseenNotificationsCount - 1;
      } else {
        draft.unseenNotificationsCount = draft.unseenNotificationsCount + 1;
      }
    });
  }


  @Action(Header.UpdateNotificationsIsSeen)
  updateNotificationsIseen(ctx) {
    mutateState(ctx, draft => {
      draft.unseenNotificationsCount = 0
    })
  }

  @Action(Header.AddNotification)
  addNotification(ctx, {count}) {
    mutateState(ctx, draft => {
      draft.unseenNotificationsCount += count;
    });
  }


  @Action(Header.UpdateUserPicture)
  updateUserPicture(ctx, {pic}) {
    mutateState(ctx, draft => {
      draft.user.picture = pic;
    })
  }


  @Action(Sider.SwitchSpace)
  switchSpace(ctx, {spaceId, redirect}) {
    if (spaceId !== this.localService.getSpace()) {
      mutateState(ctx, draft => {
        draft.isReady = false;
        draft.pages = [];
        draft.spaces = [];
        draft.primaryPages = [];
        draft.nonPrimaryPages = [];
      });
      this.webSocketService.disconnect();
      this.localService.setSpace(spaceId);
      this.localService.setActivePageSet('');
      this.cache.clearAll();
      this.dbService.clear("widgetsData").subscribe();
      this.dbService.clear("widgetsFilters").subscribe();
      return this.userService.updateObject({
        id: this.localService.getUser(),
        space: spaceId
      }).pipe(
        mergeMap(() => this.store.dispatch(new StateResetAll())),
        tap(() => {
          this.store.dispatch(new Sider.Init({isReady: !redirect})).subscribe(() => {
            if (redirect) {
              let entityId = this.store.snapshot().app.recordsPage?.entity?.id
              let pages = this.store.snapshot().app.sider.primaryPages.filter(p => p.entityId)
              if (entityId && pages.find(p => p.entityId == entityId)) {
                this.store.dispatch(new RecordsPage.Init({entity: entityId})).subscribe(() => {
                  mutateState(ctx, draft => {
                    draft.isReady = true;
                  });
                });
                this.router.navigate(['/records/' + entityId]);
              } else if (pages.length > 0) {
                this.store.dispatch(new RecordsPage.Init({
                  entity: pages[0].entityId,
                  space: this.localService.getSpace()
                })).subscribe(() => {
                  mutateState(ctx, draft => {
                    draft.isReady = true;
                  });
                });
                this.router.navigate(['/records/' + pages[0].entityId]);
              } else {
                mutateState(ctx, draft => {
                  draft.isReady = true;
                });
                this.router.navigate(['/home']);
              }
            }
            this.webSocketService.connect()
          });
        }));
    }
  }


  @Action(Header.Logout)
  logout(ctx, {}) {
    return this.userService.logout().pipe(tap(() => {
        mutateState(ctx, draft => {
          draft.pages = [];
          draft.primaryPages = [];
          draft.nonPrimaryPages = [];
          draft.user = null;
          draft.spaces = [];
        });
        this.localService.removeUser();
        this.localService.removeSpace();
        this.cache.clearAll();
        this.dbService.clear("widgetsData").subscribe();
        this.dbService.clear("widgetsFilters").subscribe();
        this.store.dispatch(
          new StateResetAll()
        );
      })
    );
  }

}
