import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {mergeMap} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ViewService extends BaseService {
  key = 'views'

  constructor(public injector: Injector) {
    super(injector)
  }

  retrieveObjects(params: object, propagations: string[] = [],extra:string[] = []): any {
    return super.retrieveObjects(params, propagations,extra).pipe(
      mergeMap((res: any) => {
        const processedData = res.data.map((result: any) => ({...result, value: _.omit(result.value, ['page'])}));
        return of({ ...res, data: processedData });
      })
    );
  }

  createObject(params: object, propagations: string[]=[]): any {
    params['value'] = _.omit(params['value'], ['page','cursor','v','page_size'])
    return super.createObject(params, propagations);
  }

  updateObject(params: object, propagations: string[]=[]): any {
    params['value'] = _.omit(params['value'], ['page','cursor','v','page_size'])
    return super.updateObject(params, propagations);
  }
}
