import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  QueryList,
  ViewChildren,
  ChangeDetectorRef, HostListener
} from '@angular/core';
import {AppState} from "../../state/app.state";
import {Select, Store} from '@ngxs/store';
import {Sider} from "../../state/app.actions";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import {LocalService} from 'src/app/services/local.service';
import { GetPageTitlePipe } from '../../pipes/get-page-title.pipe';
import { GetEntityPipe } from '../../pipes/get-entity.pipe';
import { FlattenEntitiesPipe } from '../../pipes/flatten-entities.pipe';
import { SpecialTitleCasePipe } from '../../pipes/special-title.pipe';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { TrackDirective } from '../../directives/track.directive';
import { IconComponent } from '../icon/icon.component';
import { NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, NgbAccordionButton } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgIf, NgTemplateOutlet, NgFor, AsyncPipe } from '@angular/common';
import { LoaderComponent } from "../loader/loader.component";

declare var window;
@Component({
    selector: 'app-sider',
    templateUrl: './sider.component.html',
    styleUrls: ['./sider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, TranslocoDirective, RouterLink, NgTemplateOutlet, NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, IconComponent, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, NgFor, TrackDirective, NgbAccordionButton, RouterLinkActive, AsyncPipe, NgArrayPipesModule, NgObjectPipesModule, SpecialTitleCasePipe, FlattenEntitiesPipe, GetEntityPipe, GetPageTitlePipe, LoaderComponent]
})

export class SiderComponent implements OnInit {
    @Select(AppState.sider) sider$;
    @Select(AppState.recordsPage) recordsPage$;
    @ViewChildren("menu", { read: ElementRef }) menu: QueryList<ElementRef>;
    @HostListener('window:resize')
    public onResize() {
      this.changeDetector.detectChanges();
    }
    space = this.localService.getSpace();
    isMenuOpen: boolean = false;
    activePageSet = this.localService.getActivePageSet();
    isSiderVisible = this.localService.getSiderVisibility();

    window = window;

    constructor(
        private store: Store,
        public router: Router,
        public route: ActivatedRoute,
        public localService: LocalService,
        private changeDetector: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.localService.siderVisibility$.subscribe((val) => {
            this.isSiderVisible = val;
            this.changeDetector.detectChanges();
        })
        this.store.dispatch(new Sider.Init(this.route.snapshot.params, this.route.snapshot.queryParams)).subscribe();
    }

    onPanelChange(id) {
        if (this.activePageSet !== +id) {
          this.activePageSet = +id;
          this.localService.setActivePageSet(id);
        }
      }

    adaptSpacesMenu() {
      setTimeout(() => {
        const menuElement = this.menu.toArray()[0]?.nativeElement
        if (menuElement) {
          if (menuElement.offsetHeight < menuElement.scrollHeight) {
            menuElement.classList.add('overflow-scroll')
          } else {
            menuElement.classList.remove("overflow-scroll")
          }
        }
      })
    }

    switchSpace(spaceId) {
      this.store.dispatch(new Sider.SwitchSpace(spaceId,true));
      this.isMenuOpen = !this.isMenuOpen;
    }

    isLinkActive(url): boolean {
        return this.router.isActive(url, {
            paths: 'exact',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'ignored'
        });
    }

    isSpaceActive(spaceId): boolean{
        return this.localService.getSpace() == spaceId
    }

    toggleSider() {
        this.localService.setSiderVisibility(0);
    }

    getRouterLink(page) {
        return page.recordId ? ('/record/' + page.recordId) : ('/records/' + page.entityId);
    }

    isPagesetLinkActive(pages: []) {
        return pages.some((page: any) => this.isLinkActive(this.getRouterLink(page)));
    }

    resolveActivePageSet(pages: []) {
        let activePages = pages.filter((page: any) => this.isLinkActive(this.getRouterLink(page)))
        if (activePages.length > 0 && activePages[0]['pageset'] && this.activePageSet !== activePages[0]['pageset']['id']) {
            this.activePageSet = activePages[0]['pageset']['id'];
            this.localService.setActivePageSet(String(this.activePageSet));
        }
        return this.activePageSet ? this.activePageSet : '';
    }
}
