import {State, Action, Store} from '@ngxs/store';
import {PreferencesPage, Sider} from './app.actions';
import {PreferenceService} from '../services/preference.service';
import {LocalService} from '../services/local.service';
import {mergeMap, tap} from 'rxjs/operators';
import {mutateState} from '../app.utils';
import {Inject, Injectable} from '@angular/core';
import { UserService } from '../services/user.service';
import { TranslocoService } from '@jsverse/transloco';
import { Cache } from '../classes/cache';


@State({
    name: 'preferencesPage',
    defaults: {
        preferences: [],
        isReady: false,
        userLanguage: null,
    }
})
@Injectable()
export class PreferencesPageState {

  constructor(private preferenceService: PreferenceService,
              private localService: LocalService,
              private userService: UserService,
              private translocoService: TranslocoService,
              private store: Store,
              @Inject('Cache') private cache:Cache) {
  }

    @Action(PreferencesPage.Init)
    init(ctx) {
        mutateState(ctx, draft => { draft.isReady = false, draft.userLanguage = this.localService.getLanguage(); });
        // application argument has no effect on the backend. It's used only for cache invalidation
        return this.preferenceService.retrieveObjects({space: this.localService.getSpace()}, []).pipe(
            tap((preferences: any) => {
                mutateState(ctx, draft => {
                    draft.grouped_preferences = preferences.data;
                    draft.isReady = true;
                });
            })
        );
    }

    @Action(PreferencesPage.PostPreference)
    postPreference(ctx, { preference }) {
        return this.preferenceService.createObject(preference, []).pipe(tap(p => {
            mutateState(ctx, draft => {
                for (let group of draft.grouped_preferences) {
                    let index = group.preferences.findIndex(x => x.subclassName == p["subclassName"] && x.entity == p["entity"] && x.field == p["field"])
                    if (index != -1) {
                        group.preferences[index] = p
                        break
                    }
                }
            });
        }));
    }

    @Action(PreferencesPage.UpdateUserLanguage)
    updateUserLanguage(ctx, { lang }) {
        this.cache.clearAll();
        return this.userService.updateObject({ id: this.localService.getUser(), language: lang }).pipe(
            mergeMap((user: any) => {
                this.translocoService.setActiveLang(user.language)
                this.localService.setLanguage(user.language);
                mutateState(ctx, draft => {
                    draft.userLanguage = user.language;
                });
                return this.preferenceService.retrieveObjects({space: this.localService.getSpace()}, [])
            }))
            .pipe(
                tap((preferences: any) => {
                    mutateState(ctx, draft => {
                        draft.grouped_preferences = preferences.data;
                        draft.isReady = true;
                    });
                    return this.store.dispatch(new Sider.Init({isReady: true}));
                })
            );
    }
}
