// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashed-line {
  left: 5%;
  right: 5%;
  top: 80%;
}

.clear-btn {
  top: 10%;
  left: 94%;
}

.clear-btn:hover {
  background-color: #dee2e6 !important;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/app/components/signpad/signpad.component.scss"],"names":[],"mappings":"AAAA;EACI,QAAA;EACA,SAAA;EACA,QAAA;AACJ;;AAEE;EACE,QAAA;EACA,SAAA;AACJ;;AAGA;EACI,oCAAA;EACA,0CAAA;AAAJ","sourcesContent":[".dashed-line {\n    left: 5%;\n    right: 5%;\n    top: 80%; \n  }\n\n  .clear-btn {\n    top: 10%;\n    left: 94%;\n}\n\n\n.clear-btn:hover {\n    background-color: #dee2e6 !important; \n    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
