import { enableProdMode } from '@angular/core';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './environments/environment';
import { themeView, btnTextYear } from '@event-calendar/core';
import ResourceTimeline from '@event-calendar/resource-timeline';

const originalCreateOptions = ResourceTimeline.createOptions;
ResourceTimeline.createOptions = function (options) {
  originalCreateOptions.call(this, options);
  options.views.resourceTimelineYear = {
    buttonText: btnTextYear,
    component: options.views.resourceTimelineMonth.component,
    displayEventEnd: false,
    dayHeaderFormat: {
      weekday: 'short',
      day: 'numeric'
    },
    duration: {months: 12},
    slotDuration: {days: 7},
    theme: themeView('ec-timeline ec-resource-year-view'),
    titleFormat: {year: 'numeric', month: 'long'}
  };
};

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));