export namespace RecordPage {
  export class Init {
    static readonly type = '[Record Page] Init';

    constructor(public recordId, public tabId = null, public ready = false) {
    }
  }

  export class initManyFields {
    static readonly type = '[Record Page] initManyFields';

    constructor(public recordId) {
    }
  }

  export class Destroy {
    static readonly type = '[Record Page] Destroy';

    constructor(public recordId) {
    }
  }

  export class UpdateField {
    static readonly type = '[Record Page] UpdateField';

    constructor(public recordId, public field, public fieldValue) {
    }
  }

  export class UpdateCheckboxField {
    static readonly type = '[Record Page] UpdateCheckboxField';

    constructor(public recordId, public fieldId, public fieldValue) {
    }
  }

  export class ExecuteAction {
    static readonly type = '[Record Page] ExecuteAction';

    constructor(public recordId, public actionId, public inp, public args = null) {
    }
  }

  export class CreateRecord {
    static readonly type = '[Record Page] CreateRecord';

    constructor(public recordId, public fieldsValues, public field, public indexGroupBy = null) {
    }
  }

  export class AddRecord {
    static readonly type = '[Record Page] AddRecord';

    constructor(public recordId, public childRecord, public field) {
    }
  }

  export class RemoveRecord {
    static readonly type = '[Record Page] RemoveRecord';

    constructor(public recordId, public childRecord, public field) {
    }
  }

  export class DestroyRecord {
    static readonly type = '[Record Page] DestroyRecord';

    constructor(public recordId) {
    }
  }

  export class InitBlocks {
    static readonly type = '[Record Page] InitBlocks';

    constructor(public recordId, public tab, public isUpdate = false) {
    }
  }

  export class RefreshBlocks {
    static readonly type = '[Record Page] RefreshBlocks';

    constructor(public recordId, public tab) {
    }
  }

  export class LoadSiderBlock {
    static readonly type = '[Record Page] LoadSiderBlock';

    constructor(public recordId, public blockId) {
    }
  }

  export class ToggleRecordTag {
    static readonly type = '[Record Page] ToggleRecordTag';

    constructor(public recordId, public tag) {
    }
  }

  export class EvaluateCheckpoint {
    static readonly type = '[Record Page] EvaluateCheckpoint';

    constructor(public recordId, public checkpointId, public value, public state) {
    }
  }

  export class UpdateCheckpointState {
    static readonly type = '[Record Page] UpdateCheckPointState';

    constructor(public recordId, public checkpointId, public checkpointState) {
    }
  }

  export class UpdateCheckpointType {
    static readonly type = '[Record Page] updateCheckpointType';

    constructor(public recordId, public checkpoint, public type) {
    }
  }

  export class UpdateCheckpointNotes {
    static readonly type = '[Record Page] UpdateCheckpointNotes';

    constructor(public recordId, public checkpoint, public notes) {
    }
  }

  export class CreateCheckpointTag {
    static readonly type = '[Record Page] CreateCheckpointTag';

    constructor(public recordId, public extension, public tag) {
    }
  }

  export class ToggleCheckpointTag {
    static readonly type = '[Record Page] ToggleCheckpointTag';

    constructor(public recordId, public checkpoint, public tag) {
    }
  }

  export class DeleteCheckpointTag {
    static readonly type = '[Record Page] DeleteCheckpointTag';

    constructor(public recordId, public checkpoint, public tag) {
    }
  }

  export class CreateCheckpointChoice {
    static readonly type = '[Record Page] CreateCheckpointChoice'

    constructor(public recordId, public checkpoint, public choice) {
    }
  }

  export class UpdateCheckpointChoice {
    static readonly type = '[Record Page] UpdateCheckpointChoice'

    constructor(public recordId, public checkpoint, public choice) {
    }
  }

  export class DestroyCheckpointChoice {
    static readonly type = '[Record Page] DestroyCheckpointChoice'

    constructor(public recordId, public choice) {
    }
  }

  export class UpdateCheckpointComment {
    static readonly type = '[Record Page] UpdateCheckpointComment';

    constructor(public recordId, public checkpointId, public comment) {
    }
  }

  export class CreateCheckpointAttachment {
    static readonly type = '[Record Page] CreateCheckpointAttachment';

    constructor(public recordId, public checkpoint, public file, public isStandard) {
    }
  }

  export class DestroyCheckpointAttachment {
    static readonly type = '[Record Page] DestroyCheckpointAttachment';

    constructor(public recordId, public checkpoint, public fileId, public isStandard) {
    }
  }

  export class CreateChecklistObject {
    static readonly type = '[Record Page] CreateChecklistObject';

    constructor(public recordId, public type, public object) {
    }
  }

  export class UpdateChecklistObject {
    static readonly type = '[Record Page] UpdateChecklistObject';

    constructor(public recordId, public type, public object) {
    }
  }

  export class UpdateChecklistRecord {
    static readonly type = '[Record Page] UpdateChecklist';

    constructor(public recordId, public checklistRecordId) {
    }
  }

  export class CreateComment {
    static readonly type = '[Record Page] CreateComment';

    constructor(public object, public recordId, public commentText, public isCheckpoint) {
    }
  }

  export class UpdateComment {
    static readonly type = '[Record Page] UpdateComment';

    constructor(public object, public commentText, public recordId, public commentId, public isCheckpoint) {
    }
  }

  export class DestroyComment {
    static readonly type = '[Record Page] DestroyComment';

    constructor(public object, public recordId, public commentId, public isCheckpoint) {
    }
  }

  export class UpdateReaction {
    static readonly type = '[Record Page] UpdateReaction';

    constructor(public object, public emojis, public recordId, public commentId, public isCheckpoint) {
    }
  }

  export class CreateAttachment {
    static readonly type = '[Record Page] CreateAttachment';

    constructor(public recordId, public file) {
    }
  }

  export class DestroyAttachment {
    static readonly type = '[Record Page] DestroyAttachment';

    constructor(public recordId, public id) {
    }
  }

  export class CreateFixingRecord {
    static readonly type = '[Record Page] CreateFixingRecord';

    constructor(public recordId, public spaceId, public entityId, public entityFieldId, public title, public checkpointId) {
    }
  }

  export class UpdateChecklistObjects {
    static readonly type = '[Record Page] UpdateChecklistObjects';

    constructor(public recordId, public type, public objects) {
    }
  }

  export class DeleteChecklistObject {
    static readonly type = '[Record Page] DeleteChecklistObject';

    constructor(public recordId, public type, public id) {
    }
  }

  export class UpdateRecordFields {
    static readonly type = '[Record Page] UpdateRecordFields';

    constructor(public record, public fieldValueMapping) {
    }
  }

  export class ChangeGanttScope {
    static readonly type = '[Record Page] changeGanttScope';

    constructor(public recordId, public scope, public field) {
    }
  }

  export class RetrieveTabObjects {
    static readonly type = '[Record Page] RetrieveTabObjects';

    constructor(public recordId, public element, public cursor) {
    }
  }

  export class SwitchMode {
    static readonly type = '[Record Page] ChangeMode';

    constructor(public recordId, public mode, public field) {
    }
  }

  export class UpdateCardsFilter {
    static readonly type = '[Record Page] UpdateCardsFilter';

    constructor(public recordId, public fieldId, public filters) {
    }
  }

  export class RetrieveUnscheduledRecords {
    static readonly type = '[Record Page] RetrieveUnscheduledRecords';

    constructor(public recordId, public queryParams, public cursor, public field) {
    }
  }

  export class RecordDrag {
    static readonly type = '[Record Page] RecordDrag';

    constructor(public recordId, public data) {
    }
  }
}

export namespace RecordsPage {
  export class Init {
    static readonly type = '[Records Page] Init';

    constructor(public params, public ready = false) {
    }
  }

  export class RetrieveRecords {
    static readonly type = '[Records Page] RetrieveRecords';

    constructor(public params, public cursor, public ready = false) {
    }
  }

  export class RetrieveDeferredRecordsValues {
    static readonly type = '[Records Page] RetrieveDeferredRecordsValues';

    constructor(public params, public options, public ready = false) {
    }
  }

  export class RetrieveUnscheduledRecords {
    static readonly type = '[Records Page] RetrieveUnscheduledRecords';

    constructor(public queryParams, public cursor) {
    }
  }

  export class CreateRecord {
    static readonly type = '[Records Page] CreateRecord';

    constructor(public fieldsValues, public indexGroupBy = null) {
    }
  }

  export class DestroyView {
    static readonly type = '[Records Page] DestroyView';

    constructor(public viewId) {
    }
  }

  export class CreateView {
    static readonly type = '[Records Page] CreateView';

    constructor(public view) {
    }
  }

  export class UpdateView {
    static readonly type = '[Records Page] UpdateView';

    constructor(public viewId, public viewData) {
    }
  }

  export class ImportRecords {
    static readonly type = '[Records Page] ImportRecords';

    constructor(public entityId, public csvData) {
    }
  }

  export class ChangeCalendarDates {
    static readonly type = '[Records Page] ChangeCalendarDates';

    constructor(public startDate, public endDate, public entityId, public queryParams) {
    }
  }

  export class SelectView {
    static readonly type = '[Records Page] SelectView';

    constructor(public view) {
    }
  }

  export class UpdateRecordFields {
    static readonly type = '[Records Page] UpdateRecordFields';

    constructor(public record, public fieldValueMapping) {
    }
  }

  export class RecordDrag {
    static readonly type = '[Records Page] RecordDrag';

    constructor(public data) {
    }
  }

  export class UpdateArchived {
    static readonly type = '[Records Page] UpdateArchived';

    constructor(public params, public archived) {
    }
  }

  export class UpdateHiddenViews {
    static readonly type = '[Records Page] UpdateHiddenViews'

    constructor(public hiddenViews) {
    }
  }

  export class ExecuteAction {
    static readonly type = '[Records Page] ExecuteAction';

    constructor(public queryParams, public actionId, public inp) {
    }
  }
}

export namespace HomePage {
  export class Init {
    static readonly type = '[Home Page] Init';

    constructor(public queryParams, public ready = false) {
    }
  }

  export class UploadPicture {
    static readonly type = '[Home Page] UploadPicture';

    constructor(public file) {
    }
  }

  export class RetrieveRecords {
    static readonly type = '[Home Page] RetrieveRecords';

    constructor(public currentView, public queryParams, public cursor, public ready = false) {
    }
  }

  export class RetrieveUnscheduledRecords {
    static readonly type = '[Home Page] RetrieveUnscheduledRecords';

    constructor(public currentView, public cursor) {
    }
  }

  export class ChangeCalendarDates {
    static readonly type = '[Home Page] ChangeCalendarDates';

    constructor(public startDate, public endDate, public queryParams) {
    }
  }

  export class ClearView {
    static readonly type = '[Home Page] ClearView';

    constructor(public viewId) {
    }
  }

  export class UpdateRecordFields {
    static readonly type = '[Home Page] UpdateRecordFields';

    constructor(public record, public fieldValueMapping) {
    }
  }

  export class RecordDrag {
    static readonly type = '[Home Page] RecordDrag';

    constructor(public data) {
    }
  }

}

export namespace AdministrationPage {
  export class Init {
    static readonly type = '[Administration Page] Init';

    constructor(public entityId, public model, public cursor, public search) {
    }
  }

  export class RetrieveObjects {
    static readonly type = '[Administration Page] RetrieveObjects';

    constructor(public entityId, public model, public cursor, public search) {
    }
  }

  export class SearchObjects {
    static readonly type = '[Administration Page] SearchObjects';

    constructor(public entityId, public model, public cursor, public search, public propagations = []) {
    }
  }

  export class CreateObject {
    static readonly type = '[Administration Page] CreateObject'

    constructor(public object) {
    }
  }

  export class UpdateObject {
    static readonly type = '[Administration Page] UpdateObject'

    constructor(public object, public cursor = -1) {
    }
  }

  export class DestroyObject {
    static readonly type = '[Administration Page] DestroyObject'

    constructor(public id) {
    }
  }

  export class CreateChoice {
    static readonly type = '[Administration Page] CreateChoice'

    constructor(public choice) {
    }
  }

  export class CreateChoices {
    static readonly type = '[Administration Page] CreateChoices'

    constructor(public field, public choices) {
    }
  }

  export class UpdateChoice {
    static readonly type = '[Administration Page] UpdateChoice'

    constructor(public choice) {
    }
  }

  export class DestroyChoice {
    static readonly type = '[Administration Page] DestroyChoice'

    constructor(public id) {
    }
  }

  export class RetrieveFieldChoices {
    static readonly type = '[Administration Page] RetrieveFieldChoices'

    constructor(public field, public cursor) {
    }
  }

  export class CreateDatalakeFile {
    static readonly type = '[Administration Page] CreateDatalakeFile'

    constructor(public file) {
    }
  }

  export class UpdateHiddenSections {
    static readonly type = '[Administration Page] UpdateHiddenSections'

    constructor(public hiddenSections) {
    }
  }
}

export namespace LoginPage {
  export class Login {
    static readonly type = '[LoginPage] Login';

    constructor(public email, public password, public keepMeConnected) {
    }
  }
}

export namespace ResetPage {
  export class Reset {
    static readonly type = '[ResetPage] Reset';

    constructor(public email: string, public token: string, public password: string, public confirmation: string,
                public firstName: string, public lastName: string) {
    }
  }
}

export namespace SendResetPage {
  export class SendReset {
    static readonly type = '[SendResetPage] SendReset';

    constructor(public email) {
    }
  }
}

export namespace NotificationsPage {
  export class Init {
    static readonly type = '[Notifications Page] Init';

    constructor() {
    }
  }

  export class RetrieveNotifications {
    static readonly type = '[Notifications Page] RetrieveNotifications';

    constructor(public cursor) {
    }
  }

  export class UpdateIsSeen {
    static readonly type = '[Notifications Page] UpdateIsSeen';

    constructor(public id, public isSeen) {
    }
  }

  export class UpdateNotificationsIsSeen {
    static readonly type = '[Notifications Page] UpdateNotifications';

    constructor() {
    }
  }

}

export namespace PreferencesPage {
  export class Init {
    static readonly type = '[Preferences Page] Init';

    constructor() {
    }
  }

  export class PostPreference {
    static readonly type = '[Preferences Page] PostPreference';

    constructor(public preference) {
    }
  }

  export class UpdateUserLanguage {
    static readonly type = '[Preferences Page] UpdateUserLanguage';

    constructor(public lang) {
    }
  }

}


export namespace ReleasesPage {
  export class Init {
    static readonly type = '[Releases Page] Init';

    constructor() {
    }
  }

  export class RetrieveReleases {
    static readonly type = '[Releases Page] RetrieveReleases';

    constructor(public page) {
    }
  }

}

export namespace Sider {
  export class Init {
    static readonly type = '[Sider] Init';

    constructor(public params = null, public queryParams = null) {
    }
  }

  export class SwitchSpace {
    static readonly type = '[Sider] switchSpace';

    constructor(public spaceId, public redirect = false) {
    }
  }

  export class SaveUserTableHiddenCols {
    static readonly type = '[Sider] SaveUserTableHiddenCols';

    constructor(public entityId, public columnIds) {
    }
  }

  export class SaveUserColumnsPositions {
    static readonly type = '[Sider] SaveUserColumnsPositions';

    constructor(public entityId, public columns) {
    }
  }

}

export namespace Header {
  export class Init {
    static readonly type = '[Header] Init';

    constructor(public params = null, public queryParams = null) {
    }
  }

  export class GetUnseen {
    static readonly type = '[Header] GetUnseen';

    constructor(public params = null, public queryParams = null) {
    }
  }

  export class UpdateIsSeen {
    static readonly type = '[Header] UpdateIsSeen';

    constructor(public isSeen) {
    }
  }

  export class UpdateNotificationsIsSeen {
    static readonly type = '[Header] UpdateNotificationsIsSeen';

    constructor() {
    }
  }


  export class Logout {
    static readonly type = '[Header] Logout';
  }


  export class AddNotification {
    static readonly type = '[Header] addNotification';

    constructor(public count) {
    }
  }

  export class UpdateUserPicture {
    static readonly type = '[Header] UpdateUser';

    constructor(public pic) {
    }
  }

}
