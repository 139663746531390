import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import DOMPurify from 'dompurify';

@Component({
    selector: 'field-viewer',
    templateUrl: './field-viewer.component.html',
    styleUrls: ['./field-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class FieldViewerComponent implements AfterViewInit, OnChanges {

  @Input() content;
  @Input() style;
  @Input() type;
  @ViewChild('viewerRef') viewerRef: ElementRef;

  constructor() {}

  ngOnChanges(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.init();
  }

  init(): void {
    if (this.viewerRef) {
      const viewer = new Viewer({
        el: this.viewerRef.nativeElement,
        initialValue: DOMPurify.sanitize(this.content),
        customHTMLRenderer: { 
          
          // @ts-ignore
          link: (node, context) => {
            const { origin, entering } = context;
            const result = origin();
            if (entering) {
              // result.attributes.target = '_blank';
              // result.attributes.class = 'md-anc';
            }
            return result;
          }
        }
      });

      Array.from(this.viewerRef.nativeElement.getElementsByClassName('toastui-editor-contents')).forEach((e: any) => {
        if (!this.style) {
          e.firstChild?.classList?.add("text-gray-800");
        } else {
          e.firstChild?.classList?.add("truncate", ...(this.type == 'ShortText' ? this.style.split(/\s+/) : []));
        }
      });
           
      // stop event propagation for all rendered anchor tags
      setTimeout(() => {
        document.querySelectorAll('.md-anc').forEach((anchor: HTMLElement) => {
          anchor.onclick = (e) => {
            e.stopPropagation();
          };
        });
      });
    }
  }
}
