import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { BaseService } from './base.service';
import {forkJoin, mergeMap, of} from "rxjs";
import * as _ from "lodash";
import {fieldTypeRecord} from "../app.utils";


@Injectable({
  providedIn: 'root'
})
export class EntityService extends BaseService{
  key = 'entities'
  constructor(public injector:Injector) {
    super(injector);
  }

  public retrieveMetadata(id, propagations: string[] = [], params: {} = {}): any {
    const expand = {expand: 'blocks,starting_date_field,ending_date_field,jobs,signals,tags,actions,extensions,main_field,creation_form,page,page.pageset,dasboard_read_form'};
    return super.retrieveObject(id, propagations, {...expand, ...params});
  }

  public retrieveMetadataWithMirrors(id, propagations: string[] = [], params: {} = {}): any {
    return this.retrieveMetadata(id, propagations, params).pipe(
      mergeMap((entity: any) => {
        const fields = _.filter(_.flatten(_.map(entity.blocks, 'fields')), f => fieldTypeRecord.includes(f.type) && !!f.isExpanded);
        const mirrorEntities$ = _.map(fields, f => this.retrieveMetadata(f.mirrorFields[0].entity));
        return (mirrorEntities$.length ? forkJoin(mirrorEntities$).pipe(mergeMap((entities: any) => {
          entity.mirrorEntities = entities;
          return of(entity);
        })) : of(entity));
      })
    );
  }
}
